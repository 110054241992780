import { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import EnableCache from 'classes/EnableCache';
import {
  ENABLE_FINANCING_API_INSTANCE,
  ENABLE_FINANCING_API_INSTANCE_NO_TOKEN
} from 'actions/ActionConstants';

import { setGenerateCaptchaFn } from './CaptchaService';

export function CaptchaProvider() {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    const localGenerateCaptcha = async () => {
      const cachedToken = EnableCache.get<string>("recaptcha");
      if (!cachedToken) {
        const token = await recaptchaRef.current?.executeAsync();
        if (token) {
          EnableCache.save("recaptcha", token, 2, localGenerateCaptcha);
          ENABLE_FINANCING_API_INSTANCE.defaults.headers["enable-captcha"] = token;
          ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.defaults.headers["enable-captcha"] = token;
        }
      } else {
        ENABLE_FINANCING_API_INSTANCE.defaults.headers["enable-captcha"] = cachedToken;
        ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.defaults.headers["enable-captcha"] = cachedToken;
      }
    };

    setGenerateCaptchaFn(localGenerateCaptcha);
  }, []);

  return (
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
      size="invisible"
      ref={recaptchaRef}
    />
  );
}
