import { useState, useEffect, createContext, useMemo, useContext } from 'react';
import debounce from 'lodash/debounce';
import { isSmallScreen } from 'utils/helpers';
import RequestHandler from 'classes/RequestHandler';

const FEATURE_FLAGS = {
  // now that we are using partners, we won't disable them anymore - can be deleted
  ["partners"]: "partners",
  // We want to keep loan-application-invite-sms just in case we have problems with the SMS provider.
  ["loan-application-invite-sms"]: "loan-application-invite-sms",
  // this is not enabled for all users
  ["partner-information-card"]: "partner-information-card",
  // partner is still using the old view
  ["new-partner-view"]: "new-partner-view",
  // for ne OptimizedAddMerchant popup - not sure if I can remove it
  ["display-optimized-testing-form"]: "display-optimized-testing-form",
  // keep it for now
  ["adjustment-refunds"]: "adjustment-refunds",
}

interface IFeatureFlag {
  name: string,
  is_enabled: boolean
};

interface IFeatureFlagsState {
  flags: IFeatureFlag[];
  setFlags: React.Dispatch<React.SetStateAction<IFeatureFlag[]>>;
  clearFlags: () => void;
}

const defaultContextValue: IFeatureFlagsState = {
  flags: [],
  setFlags: () => { },
  clearFlags: () => { }
};

export const FeatureFlagContext = createContext<IFeatureFlagsState>(defaultContextValue);

export const FeatureFlagProvider = ({ children }) => {
  const [flags, setFlags] = useState<Array<IFeatureFlag>>([]);

  // Method to clear flags
  const clearFlags = () => {
    setFlags([]);
  };

  const value = useMemo(() => ({ flags, setFlags, clearFlags }), [flags]);

  return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
};

export const useFlag = (flag_name: keyof typeof FEATURE_FLAGS): boolean | undefined => {
  const { flags, setFlags } = useContext(FeatureFlagContext);
  const device_type = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent) || isSmallScreen() ? "MOBILE" : "DESKTOP";
  const [flagValue, setFlagValue] = useState<boolean | undefined>(flags.find(flag => flag.name === flag_name)?.is_enabled || undefined);

  useEffect(() => {
    let isMounted = true; // Flag to check the mounted state

    const fetchFlag = async () => {
      if (flagValue !== undefined) return; // If flagValue is already set, no need to fetch
      try {
        const response = await RequestHandler.makeRequest(`feature-flags/flags/?device_type=${device_type}`, "GET");
        if (isMounted) {
          if (flags != response.data) {
            setFlags(response.data);
          }
          const fetchedFlag = response.data.find(flag => flag.name === flag_name)?.is_enabled;
          if (fetchedFlag === undefined) {
            // fetchedFlag may not exist - without setting it to false, fetchFlag loops infinitely
            setFlagValue(false);
          } else {
            setFlagValue(fetchedFlag);
          }
        }
      } catch (error) {
        console.error(error);
        if (isMounted) {
          setFlags(prev => [...prev, { name: flag_name, is_enabled: false }]);
          setFlagValue(false);
        }
      }
    };

    const debouncedFetchFlag = debounce(fetchFlag, 500);
    debouncedFetchFlag();

    return () => {
      isMounted = false;
      debouncedFetchFlag.cancel();
    };
  }, [flagValue, device_type, flags, setFlags, flag_name]);

  return flagValue;
};
