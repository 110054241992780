import styles from "./RecalculationPopup.module.scss";
import { Button, CloseIcon, Scrollable, Table } from "common";
import React, { useEffect, useRef, useState } from "react";
import { IList, IPopup } from "common/interfaces";
import Popup from "./Popup";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import { formatAmount, formatAPICurrency, formatFullname, stripOutNonDigits } from "utils/formatters";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { FormikError, Input } from "common/form";
import { LoanAmountValidation, Schema } from "utils/validation/additionalValidation";
import { useFormik } from "formik";
import { TableRef } from "common/Table";
import { DEFAULT_MAX_LOAN_AMOUNT } from "utils/constants";
import useMerchant from "hooks/useMerchant";
import BorrowerHandler from "actions/BorrowerHandler";
import useGetOffers from "hooks/useGetOffers";
import { displayMiniFeedback } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import _ from "lodash";
import Observer, { EVENTS } from "classes/Observer";

const RecalculationPopup: React.FC<IPopup> = () => {
  const [details, setDetails] = useState(null);
  const message = useAppSelector(state => state.popUp.messagePassing);
  const tableRef = useRef<TableRef>(null);
  const merchant = useMerchant();
  const [validationSchema, setValidationSchema] = useState(null);
  // a trigger for getting new offers
  const [gettingOffers, setGettingOffers] = React.useState<boolean>(false);
  const offersSaved = useAppSelector(state => state.borrower.offersList);
  const { progressStatus } = useGetOffers(message?.id, gettingOffers, null);
  const dispatch = useAppDispatch();
  // hide old offers while waiting for the new offers
  const [hideOffers, setHideOffers] = useState(false);
  const [calculatedAmount, setCalculatedAmount] = useState(0);

  useEffect(() => {
    if (progressStatus === 0) {
      setHideOffers(true);
    }
    else if (progressStatus === 100) {
      setHideOffers(false);
      setGettingOffers(false);
      Observer.trigger(EVENTS.LOAN_APP_UPDATED);
    }
  }, [progressStatus]);

  useEffect(() => {
    tableRef?.current?.reloadData();
  }, [gettingOffers]);

  // reload offers table after recalculating offers
  useEffect(() => {
    tableRef?.current?.reloadData();
  }, [offersSaved]);

  useEffect(() => {
    if (details) {
      setValidationSchema(Schema({
        loan_amount: LoanAmountValidation(details?.max_approved_amount || merchant?.max_loan_amount || DEFAULT_MAX_LOAN_AMOUNT),
      }));
    }
  }, [details]);

  const formik = useFormik({
    initialValues: { loan_amount: "$" + formatAmount(message?.loan_amount) },
    validationSchema: validationSchema,
    async onSubmit() {
    }
  });

  useEffect(() => {
    if (message?.id) {
      getApplication();
    }

    if (message) {
      setCalculatedAmount(message?.borrower?.loan_amount);
    }
  }, [message]);

  const getApplication = () => {
    LoanApplicationHandler.get(message?.id)
      .then(response => {
        setDetails(response);
      });
  }

  const getOffers = async (next: string): Promise<IList> => {
    let offersLocal = JSON.parse(JSON.stringify(offersSaved));
    if (offersLocal) {
      offersLocal.results = offersLocal?.results.map(offer => {
        return {
          ...offer,
          lender: offer.lender_name,
          monthlyPaymentTerm: formatAPICurrency(parseFloat(offer.monthly_payment)) + "/" + (offer.term_unit as string).toLowerCase().substring(0, 2) + ". for " + `${offer.term} ${offer.term_unit?.toLowerCase().substring(0, 2)}.`,
          apr: `${parseFloat(offer.apr).toFixed(2)}% ${offer.apr_type}`,
          discount: offer?.dtm_extra_data?.discount_rate ? `${formatAmount(offer?.dtm_extra_data?.discount_rate * 100 || 0, false, 2, 2)}%` : "-",
          sac: offer.dtm_extra_data?.same_as_cash_length ? `${offer?.dtm_extra_data?.same_as_cash_length} ${(offer.term_unit as string).toLowerCase().substring(0, 2)}.` : "-",
        }
      });
      if (gettingOffers) {
        offersLocal.results = [];
      }
      setCalculatedAmount(parseFloat(offersLocal?.results[0]?.amount));
    }

    return offersLocal;
  }

  const handleRecalculate = async () => {
    const payload = {
      loan_amount: parseFloat(stripOutNonDigits(formik.values.loan_amount))
    }
    const response = await BorrowerHandler.patch(payload, message?.id, null)
    if (response) {
      setGettingOffers(true);
    };
  }

  // get offers for the first time to populate the table - before any recalculation
  useEffect(() => {
    setGettingOffers(true);
  }, []);

  // will be used for Special Financing
  // const handleDecline = async () => {
  //   if (message?.id) {
  //     await LoanApplicationHandler.approveOrDenyOffer(message?.id, "decline")
  //       .then(() => {
  //         displayMiniFeedback("The offer was declined!");
  //         dispatch(popUpActions.closePopup());
  //       });
  //   }
  // }

  const handleClose = async () => {
    dispatch(popUpActions.closePopup());
  }

  const handleSend = async () => {
    if (parseFloat(stripOutNonDigits(formik.values.loan_amount)) <= details?.max_approved_amount) {
      if (message?.id) {
        await LoanApplicationHandler.approveOrDenyOffer(message?.id, "approve")
          .then(() => {
            displayMiniFeedback("The offer was successfully sent to the borrower!");
            dispatch(popUpActions.closePopup());
          });
      }
    }
  }

  return (
    <Popup isInner={true}>
      <>
        <CloseIcon id="recalculationPopup" color="dark" />
        <Scrollable styleOverride={{ maxHeight: "600px" }}>
          <div className={styles.contentContainer}>
            {details && <p className={styles.pageTitle}>
              {`${formatFullname(details?.first_name, details?.last_name)} was prequalified for up to $${formatAmount(details?.max_approved_amount)} ${offersSaved?.results ? `with ${offersSaved.results[0]?.lender_name}` : ""}`}
            </p>}
            <p className={styles.instructions}>
              These are available offers for the borrower {formatFullname(details?.first_name, details?.last_name)}. You can change the loan amount, recalculate, and send the offers to the borrower. Once you send the offers to the borrower, they receive an email to finalize the loan with the lender.
            </p>
            <div className={styles.recalculationWrapper}>
              <div className={styles.recalculationForm}>
                <div>
                  <label htmlFor="recalculation_popup_max_approved_amount">Max approved amount</label>
                  <Input
                    id="recalculation_popup_max_approved_amount"
                    name="max_approved_amount"
                    label="Max approved amount"
                    placeholder={`$${formatAmount(details?.max_approved_amount)}`}
                    disabled={true}
                    variation={2}
                  />
                </div>
                <div>
                  <label htmlFor="recalculation_popup_loan_amount">Loan amount</label>
                  <Input
                    id="recalculation_popup_loan_amount"
                    name="loan_amount"
                    label="Loan amount"
                    variation={2}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue("loan_amount", "$" + formatAmount(e.target.value), true);
                    }}
                    onBlur={() => formik.setFieldTouched("loan_amount", true)}
                    value={formik.values.loan_amount}
                    placeholder="$0"
                    mask="amount"
                    inputPrefix=""
                    className={`${formik.touched["loan_amount"] && formik.errors["loan_amount"] ? ' inputError' : ''}`}
                  />
                  <FormikError propertyName="loan_amount" formik={formik} styleOverride={{ position: "absolute", bottom: "50" }} />
                </div>
                <div>
                  <Button
                    id="recalculation_popup_recalculate"
                    label="Recalculate"
                    onClick={handleRecalculate}
                    disabled={!parseFloat(stripOutNonDigits(formik.values.loan_amount)) || parseFloat(stripOutNonDigits(formik.values.loan_amount)) > details?.max_approved_amount || parseFloat(stripOutNonDigits(formik.values.loan_amount)) === calculatedAmount}
                  />
                </div>
              </div>
              <div className={styles.tableContainer}>
                <Table
                  id="recalculation_popup_offers"
                  data={hideOffers ? () => null : getOffers}
                  tableBodyStyle={{ minWidth: 680 }}
                  headerWrapperStyle={{ minWidth: 680 }}
                  headers={[
                    { label: "Lender", value: "lender", size: 8, sortBy: "lender_name" },
                    { label: "Payment", value: "monthlyPaymentTerm", sortBy: "monthly_payment", size: 10 },
                    { label: "APR", value: "apr", sortBy: "apr", size: 6 },
                    { label: "Discount", value: "discount", sortBy: "discount_rate", size: 5 },
                    { label: "SAC", value: "sac", sortBy: "same_as_cash_length", size: 5 },
                  ]}
                  maxHeight={500}
                  ref={tableRef}
                  showEmptyTable={true}
                />
              </div>
            </div>
            <div className={styles.sendDenyWrapper}>
              <Button
                id="recalculation_popup_decline"
                label="Close"
                onClick={handleClose}
                variant="secondary"
              />
              <Button
                id="recalculation_popup_send"
                label="Send"
                onClick={handleSend}
                disabled={!parseFloat(stripOutNonDigits(formik.values.loan_amount)) || parseFloat(stripOutNonDigits(formik.values.loan_amount)) > details?.max_approved_amount || parseFloat(stripOutNonDigits(formik.values.loan_amount)) !== calculatedAmount}
              />
            </div>
          </div>
        </Scrollable>
      </>
    </Popup>
  );
};

export default RecalculationPopup;
