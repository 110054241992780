import { Grid } from "@mui/material";
import UserHandler from "actions/UserHandler";
import { useAuth } from "auth/useAuth";
import { InfoContainer, Switch } from "common";
import { SmsNotification } from "iconsax-react";
import React, { useEffect, useState } from "react";

const NotificationsTable: React.FC = () => {
  const [notifications, setNotifications] = useState([]);
  const user = useAuth()?.user;

  useEffect(() => {
    UserHandler.getNotificationSettings(user.id)
      .then(response => {
        setNotifications(response);
      });
  }, [user]);

  const handleChange = (e: any, i: number) => {
    if (notifications && notifications[i]) {
      const action = e.target.checked ? UserHandler.subscribeToNotification : UserHandler.unsubscribeFromNotification;
      action(notifications[i].id, notifications[i].notification?.name);
    }
  };

  return <InfoContainer
    id="notifications_card"
    title="Email notifications"
    icon={SmsNotification}>
      {notifications.map((notification: any, i: any) =>
        <Grid key={`email_settings_menu_${i}`} style={{ justifyContent: "space-around" }} container>
          <Grid item xs={10}><span className="fontTextMBig">{notification.notification.name}</span></Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            <Switch checked={notification.setting} onChange={handleChange} index={i} />
          </Grid>
        </Grid>
      )}
  </InfoContainer>;
}

export default NotificationsTable;