import { Input, Form } from "common/form";
import React, { useEffect, useState } from "react";
import { ISSN, IStepper } from "common/interfaces";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import actions from "reducers/BorrowerReducer";
import { Schema, getSSNValidation, getSSNorITINvalidation } from "utils/validation/additionalValidation";
import BorrowerHandler from "actions/BorrowerHandler";
import LocalStorage from "classes/LocalStorage";
import { scrollTop } from "utils/helpers";
import Analytics, { ITracking } from "classes/Analytics";
import useGetLoanApplication from "hooks/useGetLoanApplication";

const SocialSecurity: React.FC<IStepper> = ({ onNext, onBack }) => {
  const dispatch = useAppDispatch();

  const vuid = LocalStorage.get<string>("vuid");
  const applicationId = LocalStorage.get<number>("application_id");
  const [initialValues, setInitialValues] = useState({
    ssn: "",
  });
  const lenderType = useAppSelector(state => state.borrower.lender_type);

  useGetLoanApplication();

  const socialSecurity = useAppSelector(state => state.borrower.socialSecurity);

  useEffect(() => {
    if (socialSecurity) {
      setInitialValues(socialSecurity);
    }
  }, [socialSecurity]);

  const handleSubmit = async (data: ISSN) => {
    if (applicationId && vuid) {
      const response = await BorrowerHandler.saveSSN(data, applicationId, vuid);
      if (response) {
        dispatch(actions.setSocialSecurity(data));
        onNext();
      }
    }
  }

  useEffect(() => {
    scrollTop();
  }, []);

  const handleShowOrHideSSN = (show: boolean) => {
    if (show) {
      Analytics.track({ experience: "borrower", screen: "application_ssn", object: "ssn_field", action: "hidden" } as ITracking, null);
    } else {
      Analytics.track({ experience: "borrower", screen: "application_ssn", object: "ssn_field", action: "unhidden" } as ITracking, null);
    }
  }

  return <Form
    id="borrowerExperience_socialSecurity"
    buttonPosition="bottom right"
    onFormSubmit={handleSubmit}
    title="You’re almost there!"
    submitBtnText="Next"
    validationSchema={Schema({
      ssn: lenderType === "DTM" ? getSSNorITINvalidation(false) : getSSNValidation(false)
    })}
    values={initialValues}
    overrideValues={socialSecurity}
    inputFields={[
      [
        <Input type="password" name="ssn" id="borrowerExperience_ssn" label={lenderType === "DTM" ? "Social security number (SSN) or Individual Taxpayer Identification Number (ITIN)" : "Social security number (SSN)"} mask="ssn" placeholder="999-99-9999" onShowOrHide={handleShowOrHideSSN} />,
      ]
    ]}
    styleOverride={{ formContainer: { maxWidth: "45rem" } }}
    onBackClick={onBack}
    formHelp="Your SSN is used to verify your identity and check your credit. This will NOT affect your credit score. Your information is transmitted securely."
  />;
};

export default SocialSecurity;
