import { ENABLE_FINANCING_API_INSTANCE_NO_TOKEN } from "./ActionConstants";
import ErrorHandler from "./ErrorHandler";
import LocalStorage from "classes/LocalStorage";
import UserHandler from "./UserHandler";
import Observer, { EVENTS } from "classes/Observer";
import Analytics, { ITracking } from "classes/Analytics";
import { displayMiniFeedback, getAPIErrorForTracking } from "utils/helpers";

export default class AuthHandler {
  /**
   * @description Refresh token.
   * @returns {Promise<string>} Promise with new access token.
   */
  static refreshToken = async (): Promise<string> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.post('user/token/refresh/', {
        refresh: LocalStorage.get("refresh_token") || ""
      });
      // Update token in LocalStorage
      LocalStorage.save("access_token", response?.data?.access);
      Observer.trigger(EVENTS.ACCESS_TOKEN_UPDATED);

      return Promise.resolve(response?.data?.access);

    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Log in
   * @param {string} email email address.  
   * @param {string} password Password.  
   * @returns {Promise<any>} Promise with Error or user object.
   */
  static login = async (email: string, password: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.post('user/token/', { email, password });
      LocalStorage.save('access_token', response.data.access);
      LocalStorage.save('refresh_token', response.data.refresh);
      Observer.trigger(EVENTS.ACCESS_TOKEN_UPDATED);

      const userProfile = await UserHandler.getProfile();
      return Promise.resolve(userProfile);

    } catch (error) {
      Analytics.track({ experience: "portal_login", screen: "login", object: "login_attempt", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error), email });
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Password reset
   * @param {string} email email address.  
   * @returns {Promise<any>} Promise with Error or user object.
   */
  static resetPassword = async (email: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.post(`user/password_reset/`, { email });
      Analytics.track({ experience: "portal_login", screen: "reset_password_initiation", object: "valid_email", action: "submitted" } as ITracking, { email });
      return Promise.resolve(response.data);

    } catch (error) {
      if (error?.response?.status === 404) {
        Analytics.track({ experience: "portal_login", screen: "reset_password_initiation", object: "invalid_email", action: "submitted" } as ITracking, { error_name: getAPIErrorForTracking(error), email });
        displayMiniFeedback(error.response.data.detail, true);
        return Promise.reject(error.response);
      }
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Password reset confirmation
   * @param {string} token Token.  
   * @param {string} newPassword Token.  
   * @returns {Promise<any>} API response.
   */
  static confirmPasswordReset = async (token: string, newPassword: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.post(`user/password_reset/confirm/`, { token, password: newPassword });
      Observer.trigger(EVENTS.USER_ACCOUNT_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Validate reset token.
   * @param {string} token Token to be validated.
   * @returns true if token is valid.
   */
  static validateResetToken = async (token: string): Promise<boolean> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.post(`user/password_reset/validate_token/`, { token });
      return Promise.resolve(response.data?.status === "OK");

    } catch (error) {
      if (error?.response?.status === 404 && error?.response?.data?.detail === "Not found.") {
        return Promise.resolve(false);
      }
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }
}