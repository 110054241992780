import React, { useEffect, useState } from "react";
import styles from "./ApplicationProgressCard.module.scss";
import { AppIcon, ApplicationStageHorizontal, Breadcrumbs, Button } from "common";
import { CardTick, ReceiptEdit, Mouse, UsdCoin, PlayCircle, CardRemove, PathTool, TaskSquare } from "iconsax-react";
import { useParams } from "react-router-dom";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import _ from "lodash";
import { formatAPIDateFullYear, formatAmount2, formatFullname, formatAmount } from "utils/formatters";
import appActions from "reducers/AppReducer";
import { isAmountValid, isStringPresent } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import { APPLICATION_SOURCE, APP_STATUSES, POPUPS } from "utils/constants";
import { useFlag } from "hooks/useFlag";
import { useAuth } from "auth/useAuth";
import Observer, { EVENTS } from "classes/Observer";

const ApplicationProgeressCard: React.FC = () => {
  const { applicantId } = useParams();
  const dispatch = useAppDispatch();
  const applicationOffers = useAppSelector(state => state.borrower.applicationOffers);
  const [details, setDetails] = useState(null);
  const [fundedOffers, setFundedOffers] = useState<Array<any>>([]);
  const [refunds, setRefunds] = useState<any>();
  const enableAdjustmentsAndRefunds = useFlag("adjustment-refunds");
  const user = useAuth()?.user;
  const observerLoanAppUpdated = Observer.useObserver(EVENTS.LOAN_APP_UPDATED);
  // can be set for DTC or DTM
  const [progress, setProgress] = useState(null);

  useEffect(() => {
    if (details?.id && applicationEligibleForRefund()) {
      LoanApplicationHandler.getRefunds("", details?.id)
        .then(response => {
          setRefunds(response);
        });
    }
  }, [details, user?.user_type]);

  useEffect(() => {
    return () => {
      dispatch(appActions.clearBreadcrumbs());
    }
  }, []);

  useEffect(() => {
    if (applicantId) {
      getApplication();
    }
  }, [applicantId, observerLoanAppUpdated]);

  const getApplication = () => {
    LoanApplicationHandler.get(applicantId)
      .then(response => {
        setDetails(response);
        if (window.location.href.includes("bc=1")) {
          dispatch(appActions.setBreadcrumbs([
            { title: `${response?.merchant?.name}`, url: `/viewMerchant/${response?.merchant?.slug}/applications` }
          ]));
        }
      });
  }

  useEffect(() => {
    const fundedOffersTemp = [];
    _.forEach(applicationOffers, offer => {
      if (offer?.status === "Funded") {
        fundedOffersTemp.push(offer);
      }
    })
    setFundedOffers(fundedOffersTemp);
  }, [applicationOffers]);

  const iconSize = 25;

  const getFundedOffersLenders = (): string => {
    let fundedOffersLenders = "";
    if (fundedOffers.length > 1) {
      fundedOffers.forEach((fundedOffer) => {
        fundedOffersLenders += fundedOffer.lender_name + ", ";
      });
      fundedOffersLenders = fundedOffersLenders.replace(/,\s*$/, "");
      return fundedOffersLenders;
    }
  }

  const applicationFunded = isAmountValid(details?.funded_amount);
  const progressDTC = [
    isStringPresent(details?.created_at),// Started
    isStringPresent(details?.submitted_at),// Requested
    isStringPresent(details?.offered_at),// Offered
    isStringPresent(details?.clicked_at),// Clicked
    isAmountValid(details?.funded_amount),// Funded
    isStringPresent(details?.canceled_at),// Cancelled
  ];

  {/* how to differentiate between Signed and Ready??? - any timestamp, since statuses are quite messy */ }
  const progressDTM = [
    isStringPresent(details?.created_at),// Started
    // prequalified and not yet offered is showed when application has offered_at and has PENDING_WITH_MERCHANT status, but this can't be used to highlight it
    details?.source === APPLICATION_SOURCE.INVITATION ? isStringPresent(details?.submitted_at) :// requested (invitation)
      isStringPresent(details?.offered_at),// prequalified (landing page)
    details?.status === APP_STATUSES.NO_OFFERS,// No Offers
    isStringPresent(details?.offered_at),// Offered
    isStringPresent(details?.pending_with_lender_at),// Signed
    isStringPresent(details?.ready_for_funding_at),// Ready
    isAmountValid(details?.funded_amount),// Funded
    isStringPresent(details?.canceled_at),// Cancelled
  ];

  useEffect(() => {
    if (details?.lender_type === "DTC") {
      setProgress(progressDTC);
    } else if (details?.lender_type === "DTM") {
      setProgress(progressDTM);
    }
  }, [details]);

  const handleRequestRefund = (applicationDetails: any) => {
    dispatch(popUpActions.openPopup({ name: POPUPS.REQUEST_REFUND, message: applicationDetails }));
  }

  const handleLoanCancellation = (applicationDetails: any) => {
    dispatch(popUpActions.openPopup({ name: POPUPS.CANCEL_LOAN, message: applicationDetails }));
  }

  // this does not take into account if the application already have a refund in process
  const applicationEligibleForRefund = () => {
    return enableAdjustmentsAndRefunds && user?.user_type === "MERCHANT" && details?.status === APP_STATUSES.FUNDED && details?.lender_type === "DTM";
  }

  const applicationCanBeCancelled = () => {
    if (user?.user_type === "MERCHANT" && details?.lender_type !== "DTC" && details?.status !== APP_STATUSES.FUNDED && details?.status !== APP_STATUSES.CANCELED && !details?.clicked_at) {
      return true;
    }
    return false;
  }

  const refundInProcess = () => {
    if (refunds?.results?.length > 0) {
      return refunds?.results.some(refund => refund.status === "REQUESTED" || refund.status === "PENDING");
    }
  }

  const showFundedOfferHeader = applicationFunded && fundedOffers.length > 0;
  const showCancelledOfferHeader = (details?.status === APP_STATUSES.CANCELED) && applicationOffers.length >= 1;
  const showSetAmountHeader = (user?.user_type === "MERCHANT" && details?.lender_type === "DTM" && details?.status === APP_STATUSES.PENDING_WITH_MERCHANT && !details?.offered_at);

  return <>
    <div>
      {details && <>
        <div className={styles.breadcrumbsRefundWrapper}>
          <Breadcrumbs pageTitle={formatFullname(details?.first_name, details?.last_name)} />
          {applicationEligibleForRefund() && refunds && <Button id="request-refund-btn" label={refundInProcess() ? "Refund in process" : "Request refund"} disabled={refundInProcess() ? true : false} onClick={() => handleRequestRefund(details)} />}

          {applicationCanBeCancelled() && <Button id="cancel-loan-btn" label={"Cancel loan"} onClick={() => handleLoanCancellation(details)} style={{ marginRight: details?.status === APP_STATUSES.PENDING_WITH_MERCHANT ? "30px" : "0px" }} variant={details?.status === APP_STATUSES.PENDING_WITH_MERCHANT ? "secondary" : "primary"} />}
        </div>
      </>}
      {/* header for funded offers */}
      {showFundedOfferHeader && <div className={styles.cardHeader}>
        <div className={styles.headerWrapper}>
          {fundedOffers.length === 1 && <>
            <p>{formatAmount2(fundedOffers[0].funded_amount)} offer with {fundedOffers[0].lender_name}</p>
            <p className={styles.offerDetailsWrapper}>
              <span className={styles.offerDetails + " " + styles.important}>{fundedOffers[0].monthlyPayment}</span>
              <span className={styles.offerDetails}>/{(fundedOffers[0].term_unit as string).toLowerCase()} for </span>
              <span className={styles.offerDetails + " " + styles.important}>{fundedOffers[0].term}</span>
              <span className={styles.offerDetails}> {(fundedOffers[0].term_unit as string).toLowerCase()}s | </span>
              <span className={styles.offerDetails + " " + styles.important}>{fundedOffers[0].apr_num}%</span>
              <span className={styles.offerDetails}> APR</span>
            </p>
          </>
          }
          {(fundedOffers.length > 1) && <>
            <p>{formatAmount2(details?.funded_amount)} with multiple offers</p>
            <p>{getFundedOffersLenders()}</p>
          </>
          }
        </div>
      </div>
      }
      {/* header for cancelled offers */}
      {showCancelledOfferHeader && <div className={styles.cardHeader}>
        <div className={styles.headerWrapperCancel}>
          <>
            <p>{`Cancelled $${formatAmount(details?.loan_amount)} loan with ${applicationOffers[0].lender_name}`}</p>
          </>
        </div>
      </div>
      }

      {/* header for setting amount */}
      {showSetAmountHeader && <div className={styles.cardHeaderSetAmount}>
        <div className={styles.headerWrapperSetAmount}>
          <>
            <p>{`This application originates from the landing page, so you have to set the offer amount and send the offers to the applicant.`}</p>
            <div>
              <Button
                id="application_progress_card_set_amount"
                label="Set amount"
                onClick={() => {
                  dispatch(popUpActions.openPopup({ name: POPUPS.RECALCULATION, message: details }));
                }}
              />
            </div>
          </>
        </div>
      </div>
      }

      {progress && <>
        <div className={(showFundedOfferHeader || showCancelledOfferHeader || showSetAmountHeader) ? styles.card : styles.card + " " + styles.withoutHeader}>
          <div className={styles.columns}>

            {/* DTC */}
            {details?.lender_type === "DTC" && <>
              <ApplicationStageHorizontal
                icon={PlayCircle}
                status={"Started"}
                completed={progress[0]}
                date={details?.created_at}
                showLine={true}
              />

              <ApplicationStageHorizontal
                icon={ReceiptEdit}
                status={"Requested"}
                completed={progress[1]}
                date={details?.submitted_at}
                amount={details?.loan_amount}
                showLine={true}
              />

              <ApplicationStageHorizontal
                icon={UsdCoin}
                status={"Offered"}
                completed={progress[2]}
                date={details?.offered_at}
                showLine={true}
              />

              <ApplicationStageHorizontal
                icon={Mouse}
                status={"Clicked"}
                completed={progress[3]}
                date={details?.clicked_at}
                showLine={true}
              />

              <ApplicationStageHorizontal
                icon={CardTick}
                status={"Funded"}
                completed={progress[4]}
                date={details?.funded_at}
                amount={applicationFunded ? formatAmount2(details?.funded_amount) : ""}
                showLine={false}
              />

              <ApplicationStageHorizontal
                icon={CardRemove}
                status={"Cancelled"}
                completed={progress[5]}
                date={details?.canceled_at}
                showLine={false}
              />
            </>}

            {/* DTM */}
            {details?.lender_type === "DTM" && <>

              {/* for DTM show Started only if application isn't prequalified yet */}
              {!progress[1] && <ApplicationStageHorizontal
                icon={PlayCircle}
                status={"Started"}
                completed={progress[0]}
                date={details?.created_at}
                showLine={true}
              />}

              {!progress[2] && <ApplicationStageHorizontal
                icon={ReceiptEdit}
                status={details?.source === APPLICATION_SOURCE.INVITATION ? "Requested" : "Prequalified"}
                completed={progress[1]}
                date={details?.source === APPLICATION_SOURCE.INVITATION ? details?.submitted_at : details?.offered_at}
                amount={details?.loan_amount}
                showLine={true}
              />}

              {/* show only if it's highlighted */}
              {progress[2] && <ApplicationStageHorizontal
                icon={CardRemove}
                status={"No offers"}
                completed={progress[2]}
                date={details?.submitted_at}
                showLine={false}
              />}

              {!progress[2] && <>
                <ApplicationStageHorizontal
                  icon={UsdCoin}
                  status={"Offered"}
                  completed={progress[3]}
                  date={details?.offered_at}
                  amount={formatAmount2(applicationOffers[0]?.amount, true)}
                  extraInfo={user?.user_type === "MERCHANT" && details?.status === APP_STATUSES.PENDING_WITH_MERCHANT && details?.applicant_reject_reason ? "Rejected" : undefined}
                  showLine={true}
                />

                {details?.status !== APP_STATUSES.CANCELED && <ApplicationStageHorizontal
                  icon={PathTool}
                  status={"Signed"}
                  completed={progress[4]}
                  date={details?.pending_with_lender_at}
                  amount={formatAmount2(applicationOffers[0]?.amount, true)}
                  showLine={true}
                />}

                {details?.status !== APP_STATUSES.CANCELED && <ApplicationStageHorizontal
                  icon={TaskSquare}
                  status={"Ready"}
                  completed={progress[5]}
                  date={details?.ready_for_funding_at}
                  amount={formatAmount2(applicationOffers[0]?.amount, true)}
                  showLine={true}
                />}

                {details?.status !== APP_STATUSES.CANCELED && <ApplicationStageHorizontal
                  icon={CardTick}
                  status={"Funded"}
                  completed={progress[6]}
                  date={details?.funded_at}
                  amount={applicationFunded ? formatAmount2(details?.funded_amount) : ""}
                  showLine={false}
                />}

                {details?.status === APP_STATUSES.CANCELED && <ApplicationStageHorizontal
                  icon={CardRemove}
                  status={"Cancelled"}
                  completed={progress[7]}
                  date={details?.canceled_at}
                  showLine={false}
                />}
              </>}
            </>}

          </div >
        </div >
      </>
      }
    </div >
  </>;
}

export default ApplicationProgeressCard;