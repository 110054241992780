import { IInvitedUser, IList, ISendInvitation, IUser } from "common/interfaces";
import { ENABLE_FINANCING_API_INSTANCE, ENABLE_FINANCING_API_INSTANCE_NO_TOKEN } from "./ActionConstants";
import ErrorHandler from "./ErrorHandler";
import Observer, { EVENTS } from "classes/Observer";
import { getAPIErrorForTracking } from "utils/helpers";
import EnableCache from "classes/EnableCache";
import { IMerchantInvitation } from "common/interfaces/IInvitation";
import ApiCache from "classes/ApiCache";
import Analytics, { ITracking } from "classes/Analytics";
import { generateCaptcha } from "classes/CaptchaService";

export default class InvitationHandler {
  /**
   * this inviteUser isn't used anywhere 08/23/2024
   * @description Invite new user
   * @param {string} userPk User primary key.
   * @returns {Promise<any>} API response.
   */
  static inviteUser = async (userPk: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.post(`invitation/user/${userPk}/invite-user`);
      Observer.trigger(EVENTS.INVITATION_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get groups.
   * @returns {Promise<IList>} Groups.
   */
  static getGroups = async (): Promise<Array<any>> => {
    try {
      const response = await ApiCache.get(`/invitation/groups/`);
      return Promise.resolve(response.results);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get invited user information.
   * @param {number} id Invitation id
   * @param {string} visitorToken Visitor token.
   * @returns User information.
   */
  static getInvitedUserInformation = async (id: number, visitorToken: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.get(`user/${id}/?vuid=${visitorToken}`);
      return Promise.resolve({
        ...response.data,
        invitationStatus: 'VALID'
      });
    } catch (error) {
      if (error?.response?.status === 401 && error?.response?.data?.details === "Invalid visitor pass.") {
        return Promise.resolve({
          invitationStatus: 'INVALID'
        });
      } else {
        ErrorHandler(error);
      }
      return Promise.reject(error.response);
    }

  }

  /**
   * @description Save invited user information.
   * @param {number} id Invitation id
   * @param {string} visitorToken Visitor token.
   * @param {IInvitedUser} user User information.
   * @returns API response.
   */
  static saveInvitedUserInformation = async (id: number, visitorToken: string, user: IInvitedUser): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.put(`user/${id}/?vuid=${visitorToken}`, user);
      Observer.trigger(EVENTS.INVITATION_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      Analytics.track({ experience: "portal_login", screen: "create_user_account", object: "form_submission", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error), email: user.email });
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Resend or cancel invitation.
   * @param {number} user_id User id
   * @returns API response.
   */
  static resendOrCancelUserInvitation = async (user: IUser, user_id: number, action: "Cancel" | "Resend"): Promise<any> => {
    try {
      let response: any = null;
      if (action === "Resend") {
        if (user?.user_type === "PARTNER") {
          response = await ENABLE_FINANCING_API_INSTANCE.post(`users/${user_id}/resend-invite/`);
        } else {
          response = await ENABLE_FINANCING_API_INSTANCE.post(`invitation/user/${user_id}/invite-user`);
        }
      } else if (action === "Cancel") {
        if (user?.user_type === "PARTNER") {
          response = await ENABLE_FINANCING_API_INSTANCE.delete(`users/${user_id}`);
        } else {
          response = await ENABLE_FINANCING_API_INSTANCE.delete(`invitation/user/${user_id}/invite-user`);
        }
      }
      if (response !== null) {
        Observer.trigger(EVENTS.INVITATION_UPDATED);
      }
      return Promise.resolve(response?.data || null);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
  * @description Delete invitation.
  * @param {number} invitationId Invitation id.
  * @returns {Promise<any>} API response.
  */
  static deleteApplicationInvite = async (invitationId: number): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.delete(`loan-applications/applications/${invitationId}/`);
      Observer.trigger(EVENTS.INVITATION_UPDATED);
      Analytics.track({ experience: "portal", screen: "applicant_invitation_status", object: "invitation_remove_action", action: "successful" } as ITracking);
      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
* @description Re-send loan application invitation.
* @param {number} invitationId Invitation id.
* @returns {Promise<any>} API response.
*/
  static resendApplicationInvite = async (invitationId: number): Promise<any> => {

    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.post(`loan-applications/applications/${invitationId}/resend-invite/`);
      Observer.trigger(EVENTS.INVITATION_UPDATED);
      Analytics.track({ experience: "portal", screen: "applicant_invitation_status", object: "invitation_resend_action", action: "successful" } as ITracking);
      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get application invitations.
   * @param {string} next Used to get the next page.
   * @param {string} slug Merchant slug. Optional.
   * @param {boolean} preventPagination Prevent breaking the result in different pages.
   * @returns {Promise<IList>} List.
   */
  static getApplicationInvitations = async (next: string, slug?: string, preventPagination?: boolean): Promise<IList> => {
    try {
      let url = next || `loan-applications/invitations/?offset=0&limit=100`;
      if (slug) {
        url = url.AddQuerystring("merchant", slug);
      }

      if (preventPagination) {
        url = url.AddQuerystring("offset", "0").AddQuerystring("limit", "1000000");
      }

      const response = await ENABLE_FINANCING_API_INSTANCE.get(url);
      return Promise.resolve({
        ...response.data,
        originalUrl: url
      } as IList);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Upload application invites CSV.
   * @param {Blob} file_data file data.
   * @param {string} locationId Location id
   * @returns {Promise<any>} API response.
   */
  static bulkUploadApplicationInvites = async (file_data: Blob, locationId: string): Promise<any> => {
    try {
      let url = `loan-applications/applications/`;
      url = url.AddQuerystring("location", locationId);

      const reader = new FileReader();
      reader.readAsText(file_data);
      reader.onload = async (e) => {
        const content = e.target.result;
        const response = await ENABLE_FINANCING_API_INSTANCE.post(url, content, {
          headers: {
            "Content-Type": "text/csv"
          }
        });
        Observer.trigger(EVENTS.INVITATION_UPDATED);
        return Promise.resolve(response.data);
      };

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * @description Get merchant invitation id
   * @returns {Promise<any>} Invitation details.
   */
  static generateMerchantInvitation = async (): Promise<any> => {
    try {
      await generateCaptcha();
      const response = await ENABLE_FINANCING_API_INSTANCE.post(`invitation/whitelabel/merchant-invite/`);
      EnableCache.remove("recaptcha");
      Observer.trigger(EVENTS.INVITATION_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
  * @description Send loan application invitation.
  * @param {IInvitation} sendInvitation Invitation details.
  * @param {string} locationId Location id.
  * @returns {Promise<any>} API response.
  */
  static sendApplicationInvite = async (sendInvitation: ISendInvitation, locationId: string): Promise<any> => {
    try {
      const payload = {
        ...sendInvitation,
        location: locationId
      }
      const response = await ENABLE_FINANCING_API_INSTANCE.post(`loan-applications/applications/send-invite/`, payload);
      Observer.trigger(EVENTS.INVITATION_UPDATED);
      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * @description Upload merchant invites CSV.
   * @param {Blob} file_data file data.
   * @param {string} filename file name.
   * @returns {Promise<any>} API response.
   */
  static bulkUploadMerchantInvites = async (file_data: Blob, filename: string): Promise<any> => {

    try {
      const body = new FormData();
      body.append("file", file_data, filename);

      const response = await ENABLE_FINANCING_API_INSTANCE.post(`invitation/whitelabel/merchant-invite/invite-upload/`, body);
      Observer.trigger(EVENTS.INVITATION_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * @description Get merchant invites.
   * @param {string} next Used to get the next page.
   * @param {boolean} preventPagination Prevent breaking the result in different pages.
   * @returns {Promise<IList>} API response.
   */
  static getMerchantInvites = async (next: string, preventPagination: boolean = false): Promise<IList> => {
    try {
      let url = next || `invitation/whitelabel/merchant-invite?offset=0&limit=30`;
      if (preventPagination) {
        url = url.AddQuerystring("offset", "0").AddQuerystring("limit", "1000000");
      }

      const response = await ENABLE_FINANCING_API_INSTANCE.get(url);
      return Promise.resolve({
        ...response.data,
        originalUrl: url
      } as IList);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
  * @description Cancel merchant invite.
  * @param {number} invitationId Invitation id.
  * @param {"CANCEL" | "RESEND"} action Action to perform.
  * @returns {Promise<any>} API response.
  */
  static cancelOrResendMerchantInvite = async (invitationId: number, action: "CANCEL" | "RESEND"): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.put(`invitation/whitelabel/merchant-invite/${invitationId}/${action.toLowerCase()}/`);
      Observer.trigger(EVENTS.INVITATION_UPDATED);
      if (action === "CANCEL") {
        Analytics.track({ experience: "portal", screen: "merchant_invitation_status", object: "invitation_cancel_action", action: "successful" } as ITracking);
      } else if (action === "RESEND") {
        Analytics.track({ experience: "portal", screen: "merchant_invitation_status", object: "invitation_resend_action", action: "successful" } as ITracking);
      }
      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Sent a merchant invitation 
   * @param {IMerchantInvitation} invitation Merchant invitation.  
   * @returns {Promise<any>} Invitation id.
   */
  static sendMerchantInvitation = async (invitation: IMerchantInvitation): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.post(`invitation/whitelabel/merchant-invite/send/`, invitation);
      Observer.trigger(EVENTS.INVITATION_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * @description Get declined merchant information. 
   * @param {number} invitationId Invitation id.  
   * @param {string} vuid Visitor token.  
   * @returns API Response.
   */
  static getDeclinedMerchant = async (invitationId: number, vuid: string): Promise<any> => {

    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.get(`invitation/whitelabel/merchant-invite/${invitationId}/review/?vuid=${vuid}`);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Resubmit declined merchant information. 
   * @param {number} invitationId Invitation id.  
   * @param {string} vuid Visitor token.  
   * @returns API Response.
   */
  static resubmitDeclinedMerchant = async (invitationId: number, vuid: string, data: any): Promise<any> => {

    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.put(`invitation/whitelabel/merchant-invite/${invitationId}/resubmit/?vuid=${vuid}`, data);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
 * @description Get merchant invitation. 
 * @param {number} invitationId Invitation id.  
 * @param {string} vuid Visitor token.  
 * @returns API Response.
 */
  static getMerchantInvitation = async (invitationId: number, vuid: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.get(`invitation/whitelabel/merchant-invite/${invitationId}?vuid=${vuid}`);
      return Promise.resolve({
        ...response.data,
        invitationStatus: 'VALID'
      });
    } catch (error) {
      if (error?.response?.status === 401 && error?.response?.data?.details === "Invalid visitor pass.") {
        return Promise.resolve({
          invitationStatus: 'INVALID'
        });
      } else {
        ErrorHandler(error);
      }
      return Promise.reject(error.response);
    }
  }
}