import SystemHandler from "actions/SystemHandler";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "reducers/Hooks";
import { isNotNullNorUndefined, isUndefinedNullOrZero, simplifyUrl, splitFormInputFieldList } from "utils/helpers";
import { EmptySpace, Form, Input, SelectDropdown, UploadFile } from "common/form";
import * as Yup from "yup";
import { ColorPalleteDropdown, LandingPagePreview } from "common";
import useMerchant from "hooks/useMerchant";
import { BUSINESS_MODEL_OPTIONS } from "utils/constants";
import { useBusinessModel } from "hooks/useBusinessModel";
import { BusinessModelType } from "common/types";
import { RequestedLoanAmountValidation2, SimpleRequireValidation, SlugValidation } from "utils/validation/additionalValidation";
import { formatAmount, stripOutNonDigits } from "utils/formatters";

interface IEditMerchantLandingPageTemplateSettings {
  onSubmit: (data: any, logo: any) => void;
  slug?: string;
  primary_logo?: string;
  color_theme?: string;
  industry_template?: string;
  title?: string;
  landing_page_program?: BusinessModelType;
  landing_page_loan_amount?: number;
}

const EditMerchantLandingPageTemplateSettings: React.FC<IEditMerchantLandingPageTemplateSettings> = ({
  onSubmit, slug, primary_logo, color_theme, industry_template, title, landing_page_program, landing_page_loan_amount }) => {

  const [colors, setColors] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [logo, setLogo] = useState(primary_logo);
  const [file, setFile] = useState(null);
  const [newSlug, setNewSlug] = useState(slug);
  const [colorTheme, setColorTheme] = useState(null);
  const [colorOptions, setColorOptions] = useState(null);
  const [ready, setReady] = useState(false);
  const [landingPageBusinessModel, setLandingPageBusinessModel] = useState<BusinessModelType>(null);
  const [inputFields, setInputFields] = useState(null);

  const merchant = useMerchant();

  useEffect(() => {
    Promise.all([
      SystemHandler.getTemplates(),
      SystemHandler.getColorTheme()
    ]).then(responses => {
      setTemplates(responses[0]);
      setColorOptions(responses[1]?.results?.map((item: any) => { return { value: item?.id, colors: item?.colors?.map(color => `#${color}`) }; }));
      setReady(true);
    });
  }, []);

  useEffect(() => {
    if (merchant?.landing_page_program)
      setLandingPageBusinessModel(merchant?.landing_page_program);
  }, [merchant?.landing_page_program]);

  useEffect(() => {
    if (ready) {
      if (!selectedTemplate && industry_template) {
        setSelectedTemplate(templates.find((temp: any) => { return temp.id === industry_template }));
      } else {
        setSelectedTemplate(templates.find((temp: any) => { return temp.name === "Default" }));
      }
      if (color_theme) { setColors(colorOptions.find((color: any) => color.value === color_theme)?.colors) }
      if (primary_logo) { setLogo(primary_logo); }
    }
  }, [ready]);

  const handleSubmit = async (data: any) => {
    let payload: any = {
      slug: data?.slug,
      industry_template: data?.industry_template,
      color_theme: data?.color_theme,
      landing_page_program: data?.landing_page_program,
    };
    if (data?.landing_page_program === "DTM") {
      payload.landing_page_loan_amount = stripOutNonDigits(data?.requested_loan_amount);
    }

    onSubmit(payload, file);
  }

  const getValidationSchema = () => {
    return Yup.object({
      slug: SlugValidation,
      industry_template: SimpleRequireValidation,
      color_theme: SimpleRequireValidation,
      landing_page_program: (merchant?.programs?.includes("DTM") && merchant?.programs?.includes("DTC")) ? SimpleRequireValidation : undefined,
      requested_loan_amount: landingPageBusinessModel === "DTM" ? RequestedLoanAmountValidation2 : undefined,
    })
  };

  useEffect(() => {
    if (ready) {
      const inputFieldList = [
        <Input onChange={(e: any) => setNewSlug(e.target.value)} name="slug" id="editMerchantLandingPageTemplate_slug" label="Landing page URL*" />,
        <SelectDropdown onChange={(value: any) => { setSelectedTemplate(templates?.find((temp: any) => temp.value === value)) }} name="industry_template" id="editMerchantLandingPageTemplate_industry" label="Industry" selectOptions={templates} />,
        <ColorPalleteDropdown onChange={(value: string) => setColorTheme(value)} onColorChange={(colors: Array<string>) => setColors(colors)} name="color_theme" id="editMerchantLandingPageTemplate_color" label="Landing page color" />,
      ];
      if (merchant?.programs?.includes("DTM") && merchant?.programs?.includes("DTC")) {
        inputFieldList.push(
          <SelectDropdown
            selectOptions={BUSINESS_MODEL_OPTIONS}
            name="landing_page_program"
            id="editMerchantLandingPageTemplate_landing_page_program"
            label="Landing page program"
            placeholder={landing_page_program}
            onChange={(value: any) => { setLandingPageBusinessModel(value) }}
          />);
      } else if (merchant?.programs.includes("DTM")) {
        inputFieldList.push(
          <SelectDropdown
            selectOptions={BUSINESS_MODEL_OPTIONS}
            name="landing_page_program"
            id="editMerchantLandingPageTemplate_landing_page_program"
            label="Landing page program"
            disabled={true}
            placeholder={landing_page_program}
          />);
      }
      if (landingPageBusinessModel === "DTM") {
        inputFieldList.push(<Input type="text" name="requested_loan_amount" id="inviteApplicantPopup_requested_loan_amount" label="Requested loan amount" placeholder={formatAmount(landing_page_loan_amount)} mask="amount" inputPrefix="$" />);
      }

      const inputFieldsTemp = splitFormInputFieldList(inputFieldList, true);
      setInputFields(inputFieldsTemp);
    }
  }, [ready, landingPageBusinessModel, templates]);

  return <>
    {ready && inputFields && <>
      <Form
        id="editMerchantLandingPageTemplate"
        title={title || "Landing page"}
        submitBtnText="Save"
        onFormSubmit={handleSubmit}
        validationSchema={getValidationSchema()}
        values={{
          slug,
          primary_logo,
          color_theme,
          industry_template,
          landing_page_program: (merchant?.programs.includes("DTM") && !merchant?.programs.includes("DTC")) ? "" : (landing_page_program ?? merchant?.programs[0]),
          requested_loan_amount: formatAmount(landing_page_loan_amount) ?? formatAmount(25000),
        }}
        buttonPosition="bottom right"
        inputFields={inputFields}
        isInsidePopup
        beforeButtonsJSX={<div>
          <p>Borrower will we able to apply for either DTC or DTM loan, based on the selected landing page program. With DTC program the borrower will enter the requested loan amount and will be funded by the lender.</p>
          <p style={{ marginTop: "2rem" }}>With DTM you are funded directly by the lender, therefore only you can adjust the requested loan amount, however the max allowed by the lenders is $25,000. As the borrower applies they will see how much they prequalify for, up to the requested loan amount. After the borrower is prequalified you will be notified and will be able to adjust their loan amount, select the offer and send it to the borrower to finalize it with the lender.</p>
        </div>}
      />
    </>
    }
  </>;
}

export default EditMerchantLandingPageTemplateSettings;