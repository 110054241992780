let generateCaptchaFn: () => Promise<void>;

export function setGenerateCaptchaFn(fn: () => Promise<void>) {
  generateCaptchaFn = fn;
}

export async function generateCaptcha() {
  if (!generateCaptchaFn) {
    throw new Error("generateCaptchaFn not set. Did you mount the Captcha component?");
  }
  return generateCaptchaFn();
}