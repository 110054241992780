import { Icon } from "iconsax-react";
import React from "react";
import styles from "./AppIcon.module.scss";
import { v4 as uuidv4 } from 'uuid';
import { IClickable } from "./interfaces";

interface IAppIcon {
  icon: Icon;
  size?: number;
  bigCircle?: boolean;
  color?: string;
  className?: string;
  style?: any;
  /** 
   * @summary Optional - if wants to implement onClick, use this property.
   * */
  clickTrigger?: IClickable;
  circleBackground?: boolean;
  circleColor?: string;
  reverseColors?: boolean;
  rotate180deg?: boolean;
  variant?: "Linear" | "Outline" | "Broken" | "Bold" | "Bulk" | "TwoTone";
}

const AppIcon: React.FC<IAppIcon> = ({ clickTrigger, icon, className, style, size = 24, bigCircle, color = `var(--darkTextColor)`, circleBackground, circleColor, reverseColors, rotate180deg, variant = "Linear" }) => {
  let classNames = circleBackground ? styles.whiteCircle : "";
  if (circleBackground && reverseColors) {
    classNames += " " + styles.colorCircle;
    color = `var(--whiteTextColor)`;
  }
  if (bigCircle) {
    classNames += " " + styles.bigCircle;
  }
  if (rotate180deg) {
    classNames += " " + styles.rotate180deg;
  }

  return (
    // <div className={circleBackground ? styles.whiteCircle : ""}>
    <div className={classNames} style={{ backgroundColor: circleColor ? circleColor : undefined }}>
      <div
        style={{ ...style }}
        id={clickTrigger?.id ? `${clickTrigger.id}_appIconButton` : uuidv4()}
        className={clickTrigger?.onClick ? `${className || ""} ${styles.clickable}` : (className || "")}
        onClick={clickTrigger?.onClick ? () => clickTrigger.onClick() : () => { }}>
        {React.createElement(icon, { size, color, variant })}
      </div>
    </div >
  );
}
export default AppIcon;