import React from "react";
import styles from "./ApplicationStageHorizontal.module.scss";
import { Icon } from "iconsax-react";
import { AppIcon } from "common";
import { formatAmount2, formatAPIDateFullYear } from "utils/formatters";

interface IApplicationStageHorizontal {
    icon: Icon,
    status: string,
    completed: boolean,
    amount?: string,
    date?: string,
    // something like: Rejected
    extraInfo?: string,
    showLine: boolean,
}


const ApplicationStageHorizontal: React.FC<IApplicationStageHorizontal> = ({ icon, status, completed, amount, date, extraInfo, showLine }) => {
    const iconSize = 25;

    return <>
        <div className={styles.stage}>
            {showLine && <div className={styles.line} />}
            <div className={styles.icon_wrapper}>
                <AppIcon color="var(--primaryTextColor)" size={iconSize} bigCircle={true} icon={icon} circleBackground={true} circleColor={completed ? "var(--primaryColor)" : "var(--primaryColor20Solid)"} reverseColors={completed} />
            </div>
            <p className={styles.status}>{status}</p>
            {completed && <>
                {date && <p className={styles.date}>{formatAPIDateFullYear(date, false, false, true)}</p>}
                {amount && <p className={styles.amount}>{formatAmount2(amount)}</p>}
                {extraInfo && <p className={styles.rejected}>{extraInfo}</p>}
            </>}
        </div>
    </>;
}

export default ApplicationStageHorizontal;