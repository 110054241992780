import { Input, SelectDropdown, Form } from "common/form";
import { IStepper } from "common/interfaces";
import actions from "reducers/BorrowerReducer";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { isSmallScreen } from "utils/helpers";
import { LoanAmountValidation, Schema, SimpleRequireValidation } from "utils/validation/additionalValidation";
import BorrowerHandler from "actions/BorrowerHandler";
import { useEffect, useState } from "react";
import LocalStorage from "classes/LocalStorage";
import { useNavigate } from "react-router-dom";
import SystemHandler from "actions/SystemHandler";
import { APPLICATION_SOURCE, DEFAULT_MAX_LOAN_AMOUNT } from "utils/constants";
import Analytics, { ITracking } from "classes/Analytics";
import { formatAPICurrency, stripOutNonDigits } from "utils/formatters";
import useMerchant from "hooks/useMerchant";
import useGetLoanApplication from "hooks/useGetLoanApplication";

const LoanInformation: React.FC<IStepper> = ({ onNext, onBack }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const applicationLocked: boolean = LocalStorage.get<boolean>("application_locked");
  const locationId: string = LocalStorage.get<any>("selected_location")?.id;
  const merchant = useMerchant();
  const { source, loanInformation } = useAppSelector(state => state.borrower);
  const vuid = LocalStorage.get<string>("vuid");
  const applicationId = LocalStorage.get<number>("application_id");

  const [loanPurposeOptions, setLoanPurposeOptions] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const [validationSchema, setValidationSchema] = useState(null);
  const borrower = useAppSelector(state => state.borrower);

  // on this screen, this is needed if borrower went back after already filling in DOB
  useGetLoanApplication();

  useEffect(() => {
    if (loanPurposeOptions.length > 0) {
      let initValues = {
        loan_amount: (borrower?.lender_type === "DTM" && borrower?.source === "EMAIL" && loanAmountNotEditable() && loanInformation?.loan_amount) ? loanInformation.loan_amount : "",
        loan_purpose: "",
      };

      // in case user already filled in loan information in DTC, this is where loan_amount gets set
      if (loanInformation) {
        initValues = loanInformation;
      }

      let valSchema = Schema({
        loan_amount: LoanAmountValidation(merchant?.max_loan_amount || DEFAULT_MAX_LOAN_AMOUNT),
        loan_purpose: SimpleRequireValidation
      });
      if (loanAmountNotEditable()) {
        valSchema = Schema({});
      }
      else if (loanPurposeOptions.length === 1 || borrower.lender_type === "DTM") {
        valSchema = Schema({
          loan_amount: LoanAmountValidation(merchant?.max_loan_amount || DEFAULT_MAX_LOAN_AMOUNT),
        });
      }

      setInitialValues(initValues);
      setValidationSchema(valSchema);
    }
  }, [merchant, loanPurposeOptions, loanInformation]);


  useEffect(() => {
    SystemHandler.getLoanPurposeOptions().then(response => { setLoanPurposeOptions(response) });
  }, []);

  useEffect(() => {
    if (borrower.lender_type === "DTM" && (source === APPLICATION_SOURCE.INVITATION)) {
      getApplicationFromApi();
    }
  }, [source]);

  const getApplicationFromApi = async () => {
    // get the application details
    const response = await BorrowerHandler.getApplicationDataWithoutDOB(applicationId, vuid);
    if (response?.loan_amount) {
      dispatch(actions.setLoanInformation({
        loan_amount: response.loan_amount ?? 0,
      }));
    }
  }

  const handleSubmit = async (data: any) => {
    let loanAmount = data.loan_amount;
    if (typeof loanAmount === "string") {
      loanAmount = parseFloat(stripOutNonDigits(data.loan_amount))
    }

    let payload = {
      location_id: locationId,
      loan_amount: loanAmount,
      loan_purpose: data.loan_purpose
    };

    if (loanAmountNotEditable()) {
      // no need to update any info
    }
    else if (applicationId && vuid) {
      const response = await BorrowerHandler.updateLoanInformation(payload, applicationId, vuid)
      if (response) {
        dispatch(actions.setLoanInformation(data));
      }
    } else {
      navigate(`/${merchant?.slug}/borrowerExperience/StartApplication`);
    }

    if ((loanPurposeOptions.length === 1 && loanPurposeOptions[0].id === "HOME_IMPROVEMENT") || data.loan_purpose === "HOME_IMPROVEMENT") {
      LocalStorage.save("equal_housing", true);
    } else {
      LocalStorage.remove("equal_housing");
    }

    onNext();
  }

  const handleLoanAmountTooltipHover = () => {
    Analytics.track({ experience: "borrower", screen: "application_loan_info", object: "loan_amount_info", action: "hover" } as ITracking, null);
  }

  const loanAmountNotEditable = () => {
    return borrower.lender_type === "DTM" && source === APPLICATION_SOURCE.INVITATION;
  }

  return <>
    {initialValues && validationSchema && <Form
      id="borrowerExperience_loanInformation"
      buttonPosition={isSmallScreen() ? "bottom center" : "side right"}
      onFormSubmit={handleSubmit}
      title="Loan information"
      submitBtnText="Next"
      validationSchema={validationSchema}
      values={initialValues}
      overrideValues={loanInformation}
      styleOverride={{ formHelp: { marginTop: "20px", maxWidth: "70%" } }}
      inputFields={loanPurposeOptions.length > 1 && borrower.lender_type === "DTC" ? [
        [
          <Input type="text" name="loan_amount" id="borrowerExperience_loan_amount" label="Loan amount" mask="amount" inputPrefix="$" tooltip={`Loan amount should be between $1,000 and ${formatAPICurrency(merchant?.max_loan_amount || DEFAULT_MAX_LOAN_AMOUNT, false)}`} onTooltipHover={handleLoanAmountTooltipHover} />,
          <SelectDropdown id="borrowerExperience_loan_purpose" label="Loan purpose" name="loan_purpose" placeholder="Select" selectOptions={loanPurposeOptions} />
        ]
      ] : [
        [
          <Input type="text" name="loan_amount" id="borrowerExperience_loan_amount" label="Loan amount" mask="amount" inputPrefix="$" tooltip={`Loan amount should be between $1,000 and ${formatAPICurrency(merchant?.max_loan_amount || DEFAULT_MAX_LOAN_AMOUNT, false)}`} placeholder={loanAmountNotEditable() && loanInformation?.loan_amount || ""} />,
        ]
      ]}
      formHelp="Checking for loan offers is free and will NOT affect your credit score"
      disabledInputFields={applicationLocked || loanAmountNotEditable()}
    />}
  </>;
};

export default LoanInformation;
