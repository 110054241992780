import styles from "./MerchantTabs.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { Button, CalendarRange, ExportButton, FilterButton, InfoCard, Link, Table } from "common";
import { useParams } from "react-router-dom";
import { useAuth } from "auth/useAuth";
import MerchantInformationCard from "./MerchantInformationCard";
import { MerchantHouse } from "content/popups/feedback";
import { MerchantTabType } from "common/types";
import { IFeedback, IFilter, IHeader, IList } from "common/interfaces";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import { TableRef } from "common/Table";
import Observer, { EVENTS } from "classes/Observer";
import { formatAmount, formatAPICurrency, formatAPIDate, formatAPIPhone, formatAPIString, formatFullname } from "utils/formatters";
import Analytics, { ITracking } from "classes/Analytics";
import { Col, Container, Row } from "react-bootstrap";
import appActions from "reducers/AppReducer";
import { MerchantHandler } from "actions/MerchantHandler";
import { Grid } from "@mui/material";
import popUpActions from "reducers/PopUpReducer";
import { POPUPS } from "utils/constants";
import { IDateRange } from "common/Calendar";
import moment from "moment";
import useMerchant from "hooks/useMerchant";
import { displayFeedback, displayMiniFeedback, getAllStatuses, getStatus } from "utils/helpers";
import _ from "lodash";

interface IMerchantAccountTab {
  variant: MerchantTabType;
}

const MerchantAccountTab: React.FC<IMerchantAccountTab> = ({ variant }) => {
  const user = useAuth()?.user;
  const { merchantSlug } = useParams();
  const dispatch = useAppDispatch();

  const merchantApplicationFilter = useAppSelector(state => state.app.tableFilters.merchantApplication);
  const tableFilterAppliedObserver = Observer.useObserver(EVENTS.TABLE_FILTER_APPLIED);

  const merchant = useMerchant(merchantSlug);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const tableRef = useRef<TableRef>(null);
  const [preventPagination, setPreventPagination] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const selectedLocation = useAppSelector(state => state.app.selectedLocation);
  const location = user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER" ? null : selectedLocation?.id;
  const [applicationsLoaded, setApplicationsLoaded] = useState<IList>(null);

  const [merchantStatuses, setMerchantStatuses] = useState([]);

  const [isMerchantDeactivated, setIsMerchantDeactivated] = useState(false);
  const businessModelUpdated = Observer.useObserver(EVENTS.BUSINESS_MODEL_UPDATED);

  useEffect(() => {
    if (merchant) {
      getStatus("Deactivated")
        .then(response => {
          setIsMerchantDeactivated(merchant?.status === response);
        });
    }
  }, [merchant]);

  const [filter, setFilter] = useState<IFilter>({
    date_from: moment('01/01/2023', 'MM/DD/YYYY').toDate(),
    date_to: moment().toDate(),
    location: location || null
  });

  useEffect(() => {
    getAllStatuses()
      .then(response => {
        setMerchantStatuses(response);
      });
  }, []);

  useEffect(() => {
    return () => {
      dispatch(appActions.resetMerchantApplicationFilter());
    };
  }, []);

  useEffect(() => {
    MerchantHandler().getStats(merchantSlug, false, filter);
  }, [merchantSlug, filter, businessModelUpdated]);

  useEffect(() => {
    tableRef?.current?.reloadData();
  }, [merchantApplicationFilter, tableFilterAppliedObserver, filter, businessModelUpdated]);

  const getListApplications = async (next: string): Promise<IList> => {
    setLoadingData(true);
    let applications = await LoanApplicationHandler.getMany(next, { ...merchantApplicationFilter, ...filter, merchant: merchantSlug }, preventPagination, merchantSlug);
    applications.results = applications.results.map(result => {
      const location = formatAPIString(result.location?.name);
      return {
        name: <Link
          id={`application_${result.id}`}
          href={`/viewApplicant/${result.id}/application?bc=1`}
          onClick={() => {
            Analytics.track({ experience: "portal", screen: "merchants_applications", object: "application_record", action: "selected" } as ITracking, { application_id: result.id, merchant_id: result.merchant?.id });
          }}
          linkText={formatFullname(result?.first_name, result?.last_name)} />,
        phone: formatAPIPhone(result.phone_number),
        email: formatAPIString(result.email),
        location: <Link id={`viewMerchant_${result.id}`} href={`/viewMerchant/${result.merchant?.slug}/accountInformation`} linkText={location} />,
        status: result.status,
        appdate: formatAPIDate(result.created_at),
        requested: formatAPICurrency(result.loan_amount),
        funded: formatAPICurrency(result.funded_amount),
        external_id: result?.external_id || ""
      };
    });
    setLoadingData(false);
    setApplicationsLoaded(applications);
    return Promise.resolve(applications);
  }

  const applicationsHeaders: Array<IHeader> = [
    { label: "Applicant", value: "name", size: 10 },
    { label: "Location", value: "location", size: 11, sortBy: "location__name" },
    // { label: "Phone", value: "phone", size: 9 },
    { label: "App date", value: "appdate", size: 7, sortBy: "created_at" },
    // { label: "Email", value: "email", size: 13 },
    { label: "Requested", value: "requested", size: 8, sortBy: "loan_amount", align: 'right' },
    { label: "Funded", value: "funded", size: 8, sortBy: "funded_amount", align: 'right' },
    { label: "Status", value: "status", size: 8 },
  ];

  useEffect(() => {
    if (preventPagination) {
      tableRef?.current?.reloadData();
    }
  }, [preventPagination]);

  useEffect(() => {
    if (preventPagination) {
      window.DownloadCSV(tableData, [...applicationsHeaders, { label: "External ID", value: "external_id" }] as Array<any>, "Applications.csv");
      setPreventPagination(false);
    }
  }, [tableData]);

  const exportAction = () => {
    setPreventPagination(true);
  }

  const handleApprove = () => {
    MerchantHandler().approve(merchantSlug)
      .then(() => {
        MerchantHandler().get(merchantSlug);
        displayFeedback({
          title: `You have successfully approved ${merchant?.name}!`,
          body: `Their account will now become active and the main contact will get an email to create a user account.`,
          type: "MERCHANT"
        } as IFeedback);
      });
  }

  const handleDecline = (reason: string): void => {
    MerchantHandler().decline(merchantSlug, reason)
      .then(() => {
        MerchantHandler().get(merchantSlug);
        displayMiniFeedback(`You have successfully declined ${merchant?.name}!`);
        dispatch(popUpActions.closePopup());
      });
  }

  const getStatusId = (status: "Active" | "Pending" | "Deactivated" | "Declined"): number => {
    return _.find(merchantStatuses, item => { return item.name === status })?.id;
  }

  return <>
    <MerchantInformationCard variant={variant} />

    {merchant && <div className={styles.contentContainer}>

      {merchant && merchant?.status === getStatusId("Pending") && user?.user_type === "WHITELABEL" && <>
        <div className={styles.approval}>
          <h1 className={styles.approvalText}>Merchant {merchant?.name} requires approval</h1>
          <div className={styles.approvalButtons}>
            <Button id="merchantAccountInformation_approve_window_btn_approve" label="Approve" style={{ padding: "1rem" }} onClick={handleApprove} />
            <Button id="merchantAccountInformation_approve_window_btn_deny" label="Deny" style={{ padding: "1rem" }} variant="secondary" onClick={() => {
              dispatch(popUpActions.openPopup({
                name: POPUPS.DECLINE_MERCHANT,
                message: {
                  merchant: merchant,
                  onSend: handleDecline
                }
              }))
            }} />
          </div>
        </div>
      </>}

      {merchant && merchant?.status === getStatusId("Pending") && user?.user_type === "PARTNER" && <>
        <div className={styles.approval}>
          <MerchantHouse />
          <h1>Merchant {merchant?.name} is pending approval</h1>
        </div>
      </>}

      {merchant && merchant?.status !== getStatusId("Pending") && <>
        <Row className={styles.titleCalendar}>
          <Col md={12} lg={6} className={styles.titleCalendarContainer}>
            <h2>Merchant {merchant?.name}</h2>
          </Col>
          <Col md={12} lg={6} className={styles.calendarRange}>
            <CalendarRange
              showCalendarVariation={false}
              onChange={(dateRange: IDateRange) => { setFilter({ ...filter, date_from: dateRange.startDate, date_to: dateRange.endDate }) }}
              state={{ startDate: filter.date_from, endDate: filter.date_to }}
              onDateRangeChange={(dateRange: IDateRange) => {
                Analytics.track({ experience: "portal", screen: "dashboard", object: "date_range", action: "updated" } as ITracking, { days_in_range: moment(dateRange.endDate).diff(dateRange.startDate, 'days') + 1 });
              }}
            />
          </Col>
        </Row>

        <div className={styles.infoCards}>
          <InfoCard id="submitted-count" value={merchant?.submitted_count} label="# Submitted" disabled={isMerchantDeactivated} />
          <InfoCard id="approved-count" value={merchant?.approved_count} label="# Approved" disabled={isMerchantDeactivated} />
          <InfoCard id="funded-count" value={merchant?.funded_count} label="# Funded" disabled={isMerchantDeactivated} />
          <InfoCard id="approval-rate" value={formatAmount(merchant?.approval_rate * 100 || 0) + "%"} label="Approval Rate" disabled={isMerchantDeactivated} />
          <InfoCard id="submitted-amount" value={"$" + formatAmount(merchant?.submitted_total_amount?.toString())} label="Submitted" disabled={isMerchantDeactivated} />
          <InfoCard id="funded-amount" value={"$" + formatAmount(merchant?.funded_total_amount)} label="Funded" disabled={isMerchantDeactivated} />
          <InfoCard id="average-ticket-amount" value={"$" + formatAmount(merchant?.average_ticket_amount?.toString())} label="Average ticket" disabled={isMerchantDeactivated} />
          <InfoCard id="conversion-rate" value={formatAmount(merchant?.conversion_rate * 100 || 0) + "%"} label="Conversion rate" disabled={isMerchantDeactivated} />
          {/* those 3 empty Info Cards are used to position last InfoCard to the left */}
          <InfoCard id="empty" value="" label="" empty={true} disabled={isMerchantDeactivated} />
          <InfoCard id="empty" value="" label="" empty={true} disabled={isMerchantDeactivated} />
          <InfoCard id="empty" value="" label="" empty={true} disabled={isMerchantDeactivated} />
        </div>

        <Grid container>
          <Grid item sm={12} md={3} lg={2} style={{ display: "flex", alignItems: "center", height: "100%", marginTop: "auto", marginBottom: "auto" }}>
            <h2>Applications</h2>
          </Grid>
          <Grid item sm={12} md={9} lg={10} style={{ textAlign: "right" }}>
            <Button
              id="merchantApplications_invitationStatus"
              label="Invitation status"
              variant="secondary"
              onClick={() => {
                Analytics.track({ experience: "portal", screen: "merchants_applications", object: "invitation_status_button", action: "clicked" } as ITracking);
                dispatch(popUpActions.openPopup({ name: POPUPS.INVITATION_STATUS, message: "APPLICATIONS" }));
              }}
              style={{ marginRight: 20 }} />
            <FilterButton
              id="applications_filter"
              //style={{ marginLeft: "auto" }}
              onClick={() => {
                dispatch(popUpActions.openPopup({ name: POPUPS.TABLE_FILTER, message: { type: "MERCHANT_APPLICATIONS_NO_CALENDAR" } }));
              }}
              type="MERCHANT_APPLICATION"
              style={{ marginRight: 20 }}
            />
            <ExportButton
              id="merchantApplications"
              onClick={() => {
                exportAction();
                Analytics.track({ experience: "portal", screen: "merchants_applications", object: "export_button", action: "clicked" } as ITracking);
              }}
              exporting={preventPagination}
              disabled={loadingData || applicationsLoaded?.count === 0}
            />
          </Grid>
        </Grid>

        <Container fluid className={styles.tableContainer}>
          <Table
            id="applications_location"
            data={getListApplications}
            headers={applicationsHeaders}
            onUpdate={setTableData}
            tableBodyStyle={{ minWidth: 850 }}
            ref={tableRef}
            maxHeight={450}
            disabled={isMerchantDeactivated}
          />
        </Container>
      </>
      }
    </div>}

  </>;
};

export default MerchantAccountTab;
