import styles from "./TableFilterPopup.module.scss";
import { Button, CalendarRange, CloseIcon } from "common";
import { EmptySpace, Label, SelectDropdown } from "common/form";
import { statesArray } from "utils/constants";
import { IFilter, IPopup } from "common/interfaces";
import Popup from "./Popup";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { useEffect, useState } from "react";
import appActions from "reducers/AppReducer";
import popUpActions from "reducers/PopUpReducer";
import moment from "moment";
import _ from "lodash";
import { IDateRange, calendarInitialState } from "common/Calendar";
import { useAuth } from "auth/useAuth";
import { useParams } from "react-router-dom";
import SystemHandler from "actions/SystemHandler";
import { MerchantHandler } from "actions/MerchantHandler";
import Observer, { EVENTS } from "classes/Observer";
import Analytics, { ITracking } from "classes/Analytics";

const TableFilterPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const message: { type: "APPLICATIONS" | "MERCHANT" | "MERCHANT_APPLICATIONS" | "MERCHANT_APPLICATIONS_NO_CALENDAR" | "PARTNER" } = useAppSelector(state => state.popUp.messagePassing);
  const user = useAuth()?.user;
  const allLocations = useAppSelector(state => state.merchant?.locations || []);
  const selectedLocation = useAppSelector(state => state.app.selectedLocation);
  const merchantFilterData = useAppSelector(state => state.app.tableFilters.merchant);
  const partnerFilterData = useAppSelector(state => state.app.tableFilters.partner);
  const applicationFilterData = useAppSelector(state => { return message?.type === "APPLICATIONS" ? state.app.tableFilters.application : state.app.tableFilters.merchantApplication });
  const { merchantSlug } = useParams();

  const [types, setTypes] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [annualSales, setAnnualSales] = useState([]);
  const [merchantStatuses, setMerchantStatuses] = useState([]);
  const [partnerStatuses, setPartnerStatuses] = useState([]);
  const [applicationStatuses, setApplicationStatuses] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [locations, setLocations] = useState([]);
  const [numberMerchants, setNumberMerchants] = useState([]);
  const [availableComponents, setAvailableComponents] = useState(null);
  const [optionsReady, setOptionsReady] = useState(false);

  const optionAllValue = "all";

  let initialCreatedDateRange = {
    startDate: calendarInitialState.startDate,
    endDate: calendarInitialState.endDate
  }
  if (message?.type === "MERCHANT") {
    initialCreatedDateRange = {
      startDate: merchantFilterData.created_from || calendarInitialState.startDate,
      endDate: merchantFilterData.created_to || calendarInitialState.endDate
    }
  } else if (message?.type === "PARTNER") {
    initialCreatedDateRange = {
      startDate: partnerFilterData.created_from || calendarInitialState.startDate,
      endDate: partnerFilterData.created_to || calendarInitialState.endDate
    }
  }

  let screen = "applications";
  if (message?.type === "MERCHANT_APPLICATIONS") {
    screen = "merchants_applications";
  } else if (message?.type === "MERCHANT") {
    screen = "merchants";
  } else if (message?.type === "PARTNER") {
    screen = "partners"
  }

  const [applicationsDateRange, setApplicationsDateRange] = useState<IDateRange>({ startDate: applicationFilterData.date_from || calendarInitialState.startDate, endDate: applicationFilterData.date_to || calendarInitialState.endDate });
  const [createdDateRange, setCreatedDateRange] = useState<IDateRange>(initialCreatedDateRange);
  const [latestMerchantDateRange, setLatestMerchantDateRange] = useState<IDateRange>({ startDate: merchantFilterData.last_application_from || calendarInitialState.startDate, endDate: merchantFilterData.last_application_to || calendarInitialState.endDate });
  const [latestReferralDateRange, setLatestReferralDateRange] = useState<IDateRange>({ startDate: partnerFilterData.last_referral_from || calendarInitialState.startDate, endDate: partnerFilterData.last_referral_to || calendarInitialState.endDate });
  const [tempMerchantFilter, setTempMerchantFilter] = useState<IFilter>(Object.assign({}, merchantFilterData));
  const [tempPartnerFilter, setTempPartnerFilter] = useState<IFilter>(Object.assign({}, partnerFilterData));
  const [tempApplicationFilter, setTempApplicationFilter] = useState<IFilter>(Object.assign({}, applicationFilterData));

  const handleClose = () => {
    if (message?.type === "APPLICATIONS" || message?.type === "MERCHANT_APPLICATIONS" || message?.type === "MERCHANT_APPLICATIONS_NO_CALENDAR") {
      const updateAction = message?.type === "APPLICATIONS" ? appActions.updateApplicationFilter : appActions.updateMerchantApplicationFilter;
      dispatch(updateAction({ ...tempApplicationFilter, merchant: merchantSlug || tempApplicationFilter?.merchant || null }));
    } else if (message?.type === "MERCHANT") {
      dispatch(appActions.updateMerchantFilter(tempMerchantFilter));
    } else if (message?.type === "PARTNER") {
      dispatch(appActions.updatePartnerFilter(tempPartnerFilter));
    }
    Observer.trigger(EVENTS.TABLE_FILTER_APPLIED);
    Analytics.track({ experience: "portal", screen, object: "filter", action: "successful" } as ITracking);
    dispatch(popUpActions.closePopup());
  }

  const handleChange = (property: string, value: any): void => {
    if (value == optionAllValue) {
      value = "";
    }
    if (message?.type === "APPLICATIONS" || message?.type === "MERCHANT_APPLICATIONS" || message?.type === "MERCHANT_APPLICATIONS_NO_CALENDAR") {
      setTempApplicationFilter({ ...tempApplicationFilter, [property]: value || null });
    } else if (message?.type === "MERCHANT") {
      setTempMerchantFilter({ ...tempMerchantFilter, [property]: value || null });
    } else if (message?.type === "PARTNER") {
      setTempPartnerFilter({ ...tempPartnerFilter, [property]: value || null });
    }
  }

  const resetFilter = () => {
    if (message?.type === "APPLICATIONS") {
      dispatch(appActions.resetApplicationFilter());
    } else if (message?.type === "MERCHANT") {
      dispatch(appActions.resetMerchantFilter());
    } else if (message?.type === "MERCHANT_APPLICATIONS") {
      dispatch(appActions.resetMerchantApplicationFilter());
    } else if (message?.type === "MERCHANT_APPLICATIONS_NO_CALENDAR") {
      dispatch(appActions.resetMerchantApplicationFilter());
    } else if (message?.type === "PARTNER") {
      dispatch(appActions.resetPartnerFilter());
    } else {
      throw new Error("Message type not defined");
    }
    Observer.trigger(EVENTS.TABLE_FILTER_APPLIED);
    dispatch(popUpActions.closePopup());
  }

  useEffect(() => {
    setLocations(allLocations.map(location => { return { label: location.name, value: location.id } }));
  }, [allLocations]);

  useEffect(() => {
    if (message?.type === "APPLICATIONS" || message?.type === "MERCHANT_APPLICATIONS") {
      setTempApplicationFilter({
        ...tempApplicationFilter,
        date_from: applicationsDateRange.startDate ? moment(applicationsDateRange.startDate).toDate() : null,
        date_to: applicationsDateRange.endDate ? moment(applicationsDateRange.endDate).toDate() : null,
        location: selectedLocation?.id || null
      });
    } else if (message?.type === "MERCHANT_APPLICATIONS_NO_CALENDAR") {
      setTempApplicationFilter({
        ...tempApplicationFilter,
        location: selectedLocation?.id || null
      });
    } else if (message?.type === "MERCHANT") {
      setTempMerchantFilter({
        ...tempMerchantFilter,
        date_from: createdDateRange.startDate ? moment(createdDateRange.startDate).toDate() : null,
        date_to: createdDateRange.endDate ? moment(createdDateRange.endDate).toDate() : null,
        created_from: createdDateRange.startDate ? moment(createdDateRange.startDate).toDate() : null,
        created_to: createdDateRange.endDate ? moment(createdDateRange.endDate).toDate() : null,
        last_application_from: latestMerchantDateRange.startDate ? moment(latestMerchantDateRange.startDate).toDate() : null,
        last_application_to: latestMerchantDateRange.endDate ? moment(latestMerchantDateRange.endDate).toDate() : null,
      });
    } else if (message?.type === "PARTNER") {
      setTempPartnerFilter({
        ...tempPartnerFilter,
        date_from: createdDateRange.startDate ? moment(createdDateRange.startDate).toDate() : null,
        date_to: createdDateRange.endDate ? moment(createdDateRange.endDate).toDate() : null,
        created_from: createdDateRange.startDate ? moment(createdDateRange.startDate).toDate() : null,
        created_to: createdDateRange.endDate ? moment(createdDateRange.endDate).toDate() : null,
        last_referral_from: latestReferralDateRange.startDate ? moment(latestReferralDateRange.startDate).toDate() : null,
        last_referral_to: latestReferralDateRange.endDate ? moment(latestReferralDateRange.endDate).toDate() : null,
      });
    }
  }, [applicationsDateRange, createdDateRange, latestMerchantDateRange, latestReferralDateRange, selectedLocation]);

  useEffect(() => {
    Promise.all([
      SystemHandler.getAnnualSales(),
      SystemHandler.getMerchantStatuses(),
      SystemHandler.getApplicationStatuses(),
      MerchantHandler().getAll(null),
      SystemHandler.getNumberOfMerchants(),
      SystemHandler.getPartnerTypes(),
      SystemHandler.getIndustry()
    ]).then(responses => {
      setAnnualSales(responses[0]);
      setMerchantStatuses(responses[1]);
      setApplicationStatuses(responses[2]);
      setMerchants(_.sortBy(responses[3].map(merchant => { return { label: merchant?.name, value: merchant?.id } }), ["label"]));
      setNumberMerchants(responses[4]);
      setPartnerStatuses(responses[1]);
      setTypes(responses[5]);
      setIndustries(responses[6]);
      setOptionsReady(true);
    });
  }, []);

  useEffect(() => {
    if (optionsReady) {
      setAvailableComponents({
        state: <SelectDropdown
          id="tableFilterPopup_state"
          selectOptions={addAllOption(statesArray)}
          value={(message?.type === "MERCHANT" ? tempMerchantFilter?.state : (message?.type === "PARTNER" ? tempPartnerFilter.state : tempApplicationFilter?.state)) || optionAllValue}
          onChange={(option: any) => { handleChange('state', option); }} />,
        status: <SelectDropdown
          id="tableFilterPopup_status"
          selectOptions={message.type === "MERCHANT" ? addAllOption(merchantStatuses) : (message?.type === "PARTNER" ? addAllOption(partnerStatuses) : addAllOption(applicationStatuses))}
          value={(message?.type === "MERCHANT" ? tempMerchantFilter?.status : (message?.type === "PARTNER" ? tempPartnerFilter?.status : tempApplicationFilter?.status)) || optionAllValue}
          onChange={(option: any) => { handleChange('status', option); }} />,
        merchants: <SelectDropdown
          id="tableFilterPopup_merchant"
          selectOptions={addAllOption(merchants)}
          value={(message?.type === "MERCHANT" ? tempMerchantFilter?.merchant : tempApplicationFilter?.merchant) || optionAllValue}
          onChange={(option: any) => { handleChange('merchant', option); }} />,
        locations: <SelectDropdown
          id="tableFilterPopup_locations"
          selectOptions={addAllOption(locations)}
          value={(message?.type === "MERCHANT" ? tempMerchantFilter?.location : tempApplicationFilter?.location) || optionAllValue}
          onChange={(option: any) => { handleChange('location', option); }} />,
        applicationsDateRange: <CalendarRange
          showCalendarVariation
          className={styles.appDateBtn}
          state={applicationsDateRange}
          onChange={setApplicationsDateRange}
          openDateRangeOnMiddle
          title="Application date" />,
        industries: <SelectDropdown
          id="tableFilterPopup_industry"
          selectOptions={addAllOption(industries)}
          value={(message?.type === "MERCHANT" ? tempMerchantFilter?.industry : (message?.type === "PARTNER" ? tempPartnerFilter?.industry : tempApplicationFilter?.industry)) || optionAllValue}
          onChange={(option: any) => { handleChange('industry', option); }} />,
        numberMerchants: <SelectDropdown
          id="tableFilterPopup_numberMerchants"
          selectOptions={addAllOption(numberMerchants)}
          value={tempPartnerFilter?.merchant_number || optionAllValue}
          onChange={(option: any) => { handleChange('merchant_number', option); }} />,
        types: <SelectDropdown
          id="tableFilterPopup_partner_types"
          selectOptions={addAllOption(types)}
          value={tempPartnerFilter?.type || optionAllValue}
          onChange={(option: any) => { handleChange('type', option); }} />,
        annualSales: <SelectDropdown
          id="tableFilterPopup_annualSales"
          selectOptions={addAllOption(annualSales)}
          value={(message?.type === "MERCHANT" ? tempMerchantFilter?.annualSales : tempApplicationFilter?.annualSales) || optionAllValue}
          onChange={(option: any) => { handleChange('annualSales', option); }} />,
        createdDateRange: <CalendarRange
          showCalendarVariation
          className={styles.appDateBtn}
          state={createdDateRange}
          onChange={setCreatedDateRange}
          openDateRangeOnMiddle
          title="Created date" />,
        lastApplicationDateRange: <CalendarRange
          showCalendarVariation
          className={styles.appDateBtn}
          state={latestMerchantDateRange}
          onChange={setLatestMerchantDateRange}
          openDateRangeOnMiddle
          title="Last application date" />,
        lastReferralDateRange: <CalendarRange
          showCalendarVariation
          className={styles.appDateBtn}
          state={latestReferralDateRange}
          onChange={setLatestReferralDateRange}
          openDateRangeOnMiddle
          title="Last referral date" />,
        applyButton: <Button id="tableFilterPopup_apply" type="button" label="Apply" onClick={handleClose} />,
        resetFilter: <Button variant="secondary" id="tableFilterPopup_resetFilter" type="button" label="Reset" onClick={resetFilter} style={{ marginRight: 25 }} />
      })
    }
  }, [optionsReady, tempApplicationFilter, tempMerchantFilter, tempPartnerFilter]);

  const addAllOption = (array: Array<any>) => {
    return [{ label: "All", value: optionAllValue }, ...array];
  }

  const row = (column1: any, column2: any): any => {
    return <div className={styles.inputBlock}>
      <div className={styles.inputCol1}>
        {column1 || <EmptySpace />}
      </div>
      <div className={styles.inputCol2}>
        {column2 || <EmptySpace />}
      </div>
    </div>;
  }

  return (
    <Popup isInner={true} >
      {optionsReady && availableComponents && <>
        <CloseIcon id="tableFilterPopup" color="dark" />
        <h2 className={styles.popupHeader}>{`${message.type === "APPLICATIONS" || message.type === "MERCHANT_APPLICATIONS" ? 'Applications' : (message?.type === "MERCHANT" || message?.type === "MERCHANT_APPLICATIONS_NO_CALENDAR" ? 'Merchants' : 'Partners')} filters`}</h2>
        {message.type === "MERCHANT" && <>
          {row(<><Label text="State" />{availableComponents.state}</>, <><Label text="Status" />{availableComponents.status}</>)}
          {row(<><Label text="Industry" />{availableComponents.industries}</>, <><Label text="Annual sales" />{availableComponents.annualSales}</>)}
          {row(<><Label text="Created date" />{availableComponents.createdDateRange}</>, <><Label text="Last application date" />{availableComponents.lastApplicationDateRange}</>)}
          {row(null, <div style={{ textAlign: "right" }}>
            {availableComponents.resetFilter}
            {availableComponents.applyButton}
          </div>)}
        </>}
        {(message.type === "APPLICATIONS" || message.type === "MERCHANT_APPLICATIONS") && user?.user_type === "WHITELABEL" && !merchantSlug && <>
          {row(<><Label text="Applicant state" />{availableComponents.state}</>, <><Label text="Application status" />{availableComponents.status}</>)}
          {row(<><Label text="Merchant name" />{availableComponents.merchants}</>, <><Label text="Application date" />{availableComponents.applicationsDateRange}</>)}
          {row(null, <div style={{ textAlign: "right" }}>
            {availableComponents.resetFilter}
            {availableComponents.applyButton}
          </div>)}
        </>}
        {(message.type === "MERCHANT_APPLICATIONS_NO_CALENDAR") && (user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") && merchantSlug && <>
          {row(<><Label text="Applicant state" />{availableComponents.state}</>, <><Label text="Application status" />{availableComponents.status}</>)}
          {row(null, <div style={{ textAlign: "right" }}>
            {availableComponents.resetFilter}
            {availableComponents.applyButton}
          </div>)}
        </>}
        {(message.type === "APPLICATIONS" || message.type === "MERCHANT_APPLICATIONS") && (user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") && merchantSlug && <>
          {row(<><Label text="Applicant state" />{availableComponents.state}</>, <><Label text="Application status" />{availableComponents.status}</>)}
          {row(<><Label text="Application date" />{availableComponents.applicationsDateRange}</>, <div style={{ textAlign: "right", marginTop: 30 }}>
            {availableComponents.resetFilter}
            {availableComponents.applyButton}
          </div>)}
        </>}
        {(message.type === "APPLICATIONS" || message.type === "MERCHANT_APPLICATIONS") && user?.user_type === "MERCHANT" && <>
          {row(<><Label text="Applicant state" />{availableComponents.state}</>, <><Label text="Application status" />{availableComponents.status}</>)}
          {row(<><Label text="Application date" />{availableComponents.applicationsDateRange}</>, <div style={{ textAlign: "right", marginTop: 30 }}>
            {availableComponents.resetFilter}
            {availableComponents.applyButton}
          </div>)}
        </>}
        {message.type === "PARTNER" && <>
          {row(<><Label text="State" />{availableComponents.state}</>, <><Label text="Status" />{availableComponents.status}</>)}
          {row(<><Label text="Partner Type" />{availableComponents.types}</>, <><Label text="No. of merchants in network" />{availableComponents.numberMerchants}</>)}
          {row(<><Label text="Created date" />{availableComponents.createdDateRange}</>, <><Label text="Last referral date" />{availableComponents.lastReferralDateRange}</>)}
          {row(null, <div style={{ textAlign: "right" }}>
            {availableComponents.resetFilter}
            {availableComponents.applyButton}
          </div>)}
        </>}
      </>
      }
    </Popup>
  );
};

export default TableFilterPopup;
