import { Input, SelectDropdown, InputDropdown, Form } from "common/form";
import { concatenateSmartyAddress, concatenateSmartyAddressLine1and2, IAddressInfo2IUSPSAddress, removeDuplicates, scrollTop } from "utils/helpers";
import { IAddressInfo, ISmartyAddress, IStepper, IUSPSAddress } from "common/interfaces";
import { getSmartyAddresses, getUSPSAddress } from "actions/USPSActions";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import actions from "reducers/BorrowerReducer";
import { Schema, AddressValidation, SimpleRequireValidation, addressMaxChars } from "utils/validation/additionalValidation";
import BorrowerHandler from "actions/BorrowerHandler";
import LocalStorage from "classes/LocalStorage";
import SystemHandler from "actions/SystemHandler";
/* used for the address selection */
import styles from "../BorrowerExperience.module.scss";
import { AppIcon, Button } from "common";
import _ from "lodash";
import { statesArray } from "utils/constants";
import Analytics, { ITracking } from "classes/Analytics";
import { addressesAreEqual, concatenateUSPSAddress } from "utils/validation/addressValidation";
import useGetLoanApplication from "hooks/useGetLoanApplication";
import { TickCircle } from "iconsax-react";

const AddressInformation: React.FC<IStepper> = ({ onNext, onBack }) => {
  const formRef = useRef(null);
  const [addresses, setAddresses] = useState(null);
  const [propertyStatus, setPropertyStatus] = useState(null);
  const [propertyStatusOptions, setPropertyStatusOptions] = useState([]);
  const [verifyButtonEnabled, setVerifyButtonEnabled] = useState<boolean>(true);

  /* is used to fill in form's other input fields, when you select Smarty address option */
  const [overrideValues, setOverrideValues] = useState(null);

  // for address selection
  const [selectedAddress, setSelectedAddress] = useState('suggestedAddress');
  const [lastVerifiedAddress, setLastVerifiedAddress] = useState<IUSPSAddress>(null);

  useGetLoanApplication();

  useEffect(() => {
    SystemHandler.getPropertyStatusOptions()
      .then(response => {
        setPropertyStatusOptions(response);
      });
    scrollTop();
  }, [])

  const dispatch = useAppDispatch();

  const vuid = LocalStorage.get<string>("vuid");
  const applicationId = LocalStorage.get<number>("application_id");
  const applicationLocked = LocalStorage.get<boolean>("application_locked");
  const [initialValues, setInitialValues] = useState<IAddressInfo>({
    address1: "",
    address2: "",
    city: "",
    state: "",
    postal_code: "",
    property_status: "",
  });
  const addressInformation = useAppSelector(state => state.borrower.addressInformation);

  useEffect(() => {
    if (addressInformation) {
      setInitialValues(addressInformation);
    }
  }, [addressInformation]);

  /* this is used to get smarty address options - they are updated only when address line 1 is changed */
  const [formValues, setFormValues] = useState<IAddressInfo>(initialValues);
  /* address option objects - used to populate all fields when user clicks a dropdown option */
  const [smartyObjects, setSmartyObjects] = useState<Array<ISmartyAddress>>([]);
  /* address option strings - to show in a dropdown */
  const [smartyOptions, setSmartyOptions] = useState([]);
  /* When user selects an option, for example: "185 Berry St San Francisco, CA, 94107", the street address will change to a hidden option "185 Berry St", which should be in options array, but shouldn't be selectable */

  const handleNext = async () => {
    // TODO: when you set property_status and verify address, then if you select empty property_status, error message is not shown because formik wasn't touched yet for some reason - figure out why
    const isValid = await formRef.current.isValid2();

    if (isValid) {
      const payload: IAddressInfo = {
        address1: addresses[selectedAddress].Address1,
        address2: addresses[selectedAddress].Address2,
        city: addresses[selectedAddress].City,
        state: addresses[selectedAddress].State,
        postal_code: addresses[selectedAddress].Zip5,
        property_status: propertyStatus,
      };

      if (selectedAddress === "suggestedAddress") {
        Analytics.track({ experience: "borrower", screen: "application_address_info", object: "suggested_address", action: "selected" } as ITracking, null);
      } else if (selectedAddress === "typedAddress") {
        Analytics.track({ experience: "borrower", screen: "application_address_info", object: "input_address", action: "selected" } as ITracking, null);
      }

      dispatch(actions.setAddressInformation(payload));

      if (applicationId && vuid) {
        const response = await BorrowerHandler.saveAddressInformation(payload, applicationId, vuid);
        if (response) {
          onNext();
        }
      }
    }
  }

  const getAddress = (address: IUSPSAddress, name: string): JSX.Element => {
    if (name === "suggestedAddress") {
      return <div className={styles.contactInfo}>
        <input id={`address_${name}`} type="radio" value={name} name={"address"} onChange={e => setSelectedAddress(e.target.value)} defaultChecked={true} />
        <p className="menu">{concatenateUSPSAddress(address)}</p>
        <AppIcon icon={TickCircle} variant="Bold" color={`var(--greenColor)`} />
        {/* for UI testing */}
        <input id={`hidden_${name}`} type="hidden" value={address.Address1} />
      </div>;
    } else {
      if (!addresses.suggestedAddress && selectedAddress !== name) {
        setSelectedAddress(name);
      }

      return (
        <div className={styles.contactInfo}>
          <input id={`address_${name}`} type="radio" value={name} name={"address"} onChange={e => setSelectedAddress(e.target.value)} defaultChecked={addresses.suggestedAddress ? false : true} />
          <p className="menu">Your entry:</p>
          <p style={{ color: `var(--placeholderColor)` }}>{concatenateUSPSAddress(address)}</p>
          {/* for UI testing */}
          <input id={`hidden_${name}`} type="hidden" value={address.Address1} />
        </div>);
    }
  }

  const handleVerify = async (data: IAddressInfo) => {
    setPropertyStatus(data.property_status);

    const typedAddress: IUSPSAddress = IAddressInfo2IUSPSAddress(data);

    if (applicationId && vuid) {
      try {
        const suggestedAddress = await getUSPSAddress(typedAddress);
        setVerifyButtonEnabled(false);

        if (suggestedAddress) {
          setLastVerifiedAddress(typedAddress);
          const addressesEqual = addressesAreEqual(typedAddress, suggestedAddress);

          if (addressesEqual) {
            setAddresses({ suggestedAddress });
          } else {
            setAddresses({ typedAddress, suggestedAddress });
          }
        } else {
          setAddresses({ typedAddress });
        }
      } catch (error) {
        Analytics.track({ experience: "borrower", screen: "application_address_info", object: "address_validation", action: "bypassed" } as ITracking, null);
        setAddresses({ typedAddress });
        console.log(error);
      }
    }
  }

  const getSmartyOptions = async () => {
    const typedAddress: IUSPSAddress = IAddressInfo2IUSPSAddress(formValues);

    const optionsFromResponse: Array<ISmartyAddress> = await getSmartyAddresses(typedAddress);
    setSmartyObjects(optionsFromResponse);

    let options = optionsFromResponse.map((address: ISmartyAddress) => {
      const addressLabel: string = concatenateSmartyAddress(address);
      return { label: addressLabel, value: addressLabel };
    });

    let hiddenOptions = removeDuplicates(optionsFromResponse.map((address: ISmartyAddress) => {
      const addressLabel: string = concatenateSmartyAddressLine1and2(address);
      return { label: addressLabel, value: addressLabel };
    }));

    setSmartyOptions(options);
  }

  const getAddressSelector = () => {
    return !addresses ? <></> : (
      <>
        <div className={styles.addressSelector} >
          <h3>Suggested verified addresses based on your entry:</h3>
          {addresses["suggestedAddress"] && getAddress(addresses.suggestedAddress, "suggestedAddress")}
          {addresses["typedAddress"] && getAddress(addresses.typedAddress, "typedAddress")}
        </div>
        <div className={styles.buttonRight}>
          <Button
            className={styles.buttonRight}
            id="addressSelectorPopup_confirm"
            type="button"
            label="Next"
            onClick={handleNext}
          />
        </div>
      </>
    );
  };

  const handleAddress1Changed = (data: IAddressInfo) => {
    setFormValues(data);
  }

  useEffect(() => {
    if (addresses) {
      Analytics.track({ experience: "borrower", screen: "application_address_info", object: "address_validation", action: "prompted" } as ITracking, null);
    }
  }, [addresses]);

  const handleChange = (data: IAddressInfo, changedFields: Array<string>) => {
    const typedAddress: IUSPSAddress = IAddressInfo2IUSPSAddress(data);

    // enable or disable verify button
    if (addressesAreEqual(typedAddress, lastVerifiedAddress)) {
      setVerifyButtonEnabled(false);
    } else {
      setVerifyButtonEnabled(true);
    }

    if (changedFields.includes("address1")) {
      // find selected smarty option
      const selectedObject: ISmartyAddress = _.filter(smartyObjects, item => { return concatenateSmartyAddress(item) === data.address1 })[0];

      // populate form's fields with Smarty address values
      if (selectedObject) {
        const selectedSmartyAddress = {
          address1: concatenateSmartyAddressLine1and2(selectedObject),
          // address2: selectedObject.secondary,
          city: selectedObject.city,
          state: selectedObject.state,
          postal_code: selectedObject.zipcode,
        }
        setOverrideValues(selectedSmartyAddress);
      }
    }
  }

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      const addressAlreadySearchedFor = _.filter(smartyObjects, item => { return concatenateSmartyAddressLine1and2(item) === formValues.address1 }).length > 0;

      if (!addressAlreadySearchedFor && formValues.address1) {
        getSmartyOptions();
      }
    }, 350);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [formValues]);

  return <>
    <Form
      id="borrowerExperience"
      buttonPosition="side right"
      buttonStyle={addresses ? "secondary" : "primary"}
      buttonDisabled={!verifyButtonEnabled}
      onFormSubmit={handleVerify}
      title="Address information"
      submitBtnText="Verify"
      validationSchema={Schema({
        ...AddressValidation,
      })}
      validationSchema2={Schema({
        property_status: SimpleRequireValidation,
      })}
      values={initialValues}
      inputFields={[
        [
          <InputDropdown
            name="address1"
            id="borrowerExperience_address1"
            label="Street address"
            selectOptions={smartyOptions}
            freeSolo={true}
          />,
          /* we will probably need this later */
          // <Input type="text" name="address2" id="borrowerExperience_address2" label="Address line 2" placeholder="Apt, Suite, Unit" maxLength={addressMaxChars} />,
          <Input type="text" name="city" id="borrowerExperience_city" label="City" maxLength={addressMaxChars} />,
        ],
        [
          <SelectDropdown
            id="borrowerExperience_state"
            label="State"
            name="state"
            placeholder="Select"
            selectOptions={statesArray}
          />,
          <Input type="text" name="postal_code" id="borrowerExperience_postal_code" label="Postal code" maxLength={5} />,
        ],
        [
          <SelectDropdown
            id="borrowerExperience_property_status"
            label="Property status"
            name="property_status"
            placeholder="Select"
            selectOptions={propertyStatusOptions}
          />,
        ]
      ]}
      onBackClick={onBack}
      disabledInputFields={applicationLocked}
      endOfFormJSX={getAddressSelector()}
      onFormChange={handleChange}
      // first try to reinitialize fields after possible page refresh, later, it can fill in fields after selecting smarty address option
      overrideValues={overrideValues || addressInformation}
      listenToFieldChange="address1"
      onListenedFieldChange={handleAddress1Changed}
      ref={formRef}
    />
  </>
};

export default AddressInformation;
