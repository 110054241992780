import React from "react";
import "utils/global";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  WhiteLabelLanding, CreditAuthorization, ESignAgreement, OfferInfo,
  Offers, OfferSelected, PrivacyPolicy, SetupMerchant, TermsConditions, VerifyDOB,
  NoPage, MerchantLanding, VerifyDOBandSSN, Deactivated, AdvertiserDisclosure, Unsubscribed, OfferRejected
} from "content";
import { Help, NewDashboard } from "content/portals";
import ProtectedRoute from "auth/ProtectedRoute";
import { AuthProvider } from "auth/useAuth";
import { FullscreenLoading, LocationTracker, MiniFeedback, PageTracker, URLHandler } from "common";
import {
  AuthRoutes, BorrowerExperienceRoutes, MerchantInvitationRoutes,
  SettingsRoutes, UserInvitationRoutes, ViewApplicationRoutes, WhiteLabelRoutes
} from "routes";
import SvgTest from "content/LandingPages/SvgTest";
import { useApp } from "App.hooks";
import WhiteLabelSettingsView from "content/portals/settings/whiteLabel/WhiteLabelSettingsView";
import BackdropOverlay from "content/popups/BackdropOverlay";
import FullScreenPopups from "content/popups/FullScreenPopups";
import Test from "Test";
import AllPopupsTest from "AllPopupsTest";
import { CaptchaProvider } from "classes/CaptchaProvider";

const App: React.FC = () => {
  const { wlCalled } = useApp();

  return <>
    <FullscreenLoading />
    {wlCalled && <>
      <BrowserRouter>
        <PageTracker>
          <AuthProvider>
            <BackdropOverlay />
            <LocationTracker />
            <Routes>
              {AuthRoutes()}
              {UserInvitationRoutes()}
              {BorrowerExperienceRoutes()}
              {MerchantInvitationRoutes()}
              {WhiteLabelRoutes()}
              {ViewApplicationRoutes()}
              {SettingsRoutes()}
              <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/termsConditions" element={<TermsConditions />} />
              <Route path="/advertiserDisclosure" element={<AdvertiserDisclosure />} />
              <Route path="/dashboard" element={<ProtectedRoute> <NewDashboard /> </ProtectedRoute>} />
              <Route path="/landing" element={<WhiteLabelLanding />} />
              <Route path="/creditAuthorization" element={<CreditAuthorization />} />
              <Route path="/eSignAgreement" element={<ESignAgreement />} />
              <Route path="/offerInfo" element={<OfferInfo />} />
              <Route path="/offers" element={<Offers />} />
              <Route path="/offerSelected" element={<OfferSelected />} />
              <Route path="/offerRejected" element={<OfferRejected />} />
              <Route path="/setupMerchant" element={<SetupMerchant />} />
              <Route path="/verifyDOB" element={<VerifyDOB path="VerifyDOB" screen="application_verify_dob" />} />
              <Route path="/verifyDOBandSSN" element={<VerifyDOBandSSN path="VerifyDOBandSSN" screen="application_verify_dob_ssn" />} />
              <Route path="/help" element={<Help />} />
              {(process.env.REACT_APP_ENV?.toLowerCase() === "dev" || process.env.REACT_APP_ENV?.toLowerCase() === "development") && <Route path="/allpopupstest" element={<AllPopupsTest />} />}
              <Route path="/notFound" element={<NoPage />} />
              <Route path="/unsubscribe/:whitelabelId/:notification" element={<URLHandler redirectUrl={"/"} requireVisitorPass>{<Unsubscribed />}</URLHandler>} />
              <Route path="/:merchantSlug" element={<MerchantLanding />} />
              <Route path="/svgtest" element={<SvgTest />} />
              <Route path="/deactivated" element={<Deactivated />} />
              <Route path="/settings2/:view" element={<WhiteLabelSettingsView />} ></Route>
              <Route path="/settings2" element={<WhiteLabelSettingsView />} ></Route>
              <Route path="/test" element={<Test />} ></Route>
              <Route path="/settings/notifications" element={<Navigate to="/settings/yourSettings" replace />} />
              <Route path="/" element={<WhiteLabelLanding />} />
              <Route path="*" element={<NoPage />} />
            </Routes>
            <FullScreenPopups />
            <MiniFeedback />
          </AuthProvider>
        </PageTracker>
      </BrowserRouter>
    </>}
    <CaptchaProvider />
  </>;
};

export default App;
