import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Landing from 'common/Landing';
import { useAppSelector, useAppDispatch } from "reducers/Hooks";
import LocalStorage from "classes/LocalStorage";
import {
  applicationHasDOB, displayFeedback, displayFullscreenLoading,
  getQueryString, hideFullscreenLoading
} from "utils/helpers";
import appActions from "reducers/AppReducer";
import { useAuth } from "auth/useAuth";
import { MerchantHandler } from "actions/MerchantHandler";
import { IFeedback } from "common/interfaces";
import { DeactivatedMerchantHandler } from "actions/ErrorHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { calculateColorVariables } from "utils/colors";
import borrowerActions from "reducers/BorrowerReducer";
import { APPLICATION_SOURCE } from "utils/constants";

const MerchantLanding: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const logout = useAuth()?.logout;
  const user = useAuth()?.user;

  const vuid = LocalStorage.get<string>("vuid");
  const applicationId = LocalStorage.get<number>("application_id");

  const merchantSettings = useAppSelector(state => state.merchant);
  const whitelabelSlug = useAppSelector(state => state.whitelabel?.slug);
  const whitelabelName = useAppSelector(state => state.whitelabel?.name);
  const [ctaLink, setCtaLink] = useState<string>("");

  let { merchantSlug } = useParams();

  const getSettings = async () => {
    displayFullscreenLoading();
    const response = await MerchantHandler(DeactivatedMerchantHandler).get(merchantSlug, true);

    if (response != null) {
      if (response.color_theme) {
        const style = document.documentElement.style;
        const variables = calculateColorVariables(response.color_theme["primary_light"], response.color_theme["secondary"]);
        variables.forEach(item => {
          style.setProperty(item.variable_name, item.color_code);
        });
      }

      let applicationHasDOBLocal = null;
      if (vuid && applicationId) {
        // find out if application already has DOB or not - if not, application was dropped of after loan information screen - in this case, create a new one
        applicationHasDOBLocal = await applicationHasDOB(applicationId, vuid)
      }

      if (vuid && applicationId && applicationHasDOBLocal) {
        setCtaLink(`../verifyDOB`);
      }
      else if (vuid && applicationId && !applicationHasDOBLocal) {
        // since application only has loan information, you can't verify it, so just create a new one
        setCtaLink(`../${merchantSlug}/location`);
        LocalStorage.remove("vuid");
        LocalStorage.remove("application_id");
        LocalStorage.remove("selected_location");
        LocalStorage.remove("dob");
      }
      else if (response.locations.length > 1 || response.locations.length === 0) {
        setCtaLink(`../${merchantSlug}/location`);
      }
      else if (response.locations.length === 1) {
        LocalStorage.save("selected_location", response.locations[0]);
        dispatch(appActions.selectLocation(response.locations[0]));
        setCtaLink(`../${merchantSlug}/borrowerExperience`);
      }
      hideFullscreenLoading();
    } else {
      hideFullscreenLoading();
      navigate('/notFound');
    }
  }

  useEffect(() => {
    if (user?.id) {
      logout(false);
    }
    dispatch(appActions.clearLocation());
  }, [useAuth]);

  useEffect(() => {
    getSettings();
  }, [whitelabelSlug, whitelabelName]);

  // in case m query parameter is present in merchant landing page, display a feedback with that message and delete the url, so the user can't see it
  useEffect(() => {
    const params = getQueryString(`${window.location.pathname}${window.location.search}`);
    if (params?.m) {
      displayFeedback({
        title: "Invitation expired",
        body: params.m,
        type: "USER"
      } as IFeedback);
      navigate(window.location.pathname, { replace: true });
    }
  }, []);

  const handleCtaLinkClick = () => {
    dispatch(borrowerActions.setApplicationSource(APPLICATION_SOURCE.LANDING));
    // link can also point to location selector, if there is more than 1 location
    if (ctaLink === `../${merchantSlug}/borrowerExperience`) {
      Analytics.track({ experience: "borrower", screen: "application_select_location", object: "form_submission", action: "bypassed" } as ITracking, null);
    }
  }

  return (
    <>
      {merchantSettings && <Landing
        id="merchantLanding"
        ctaLink={ctaLink}
        title={merchantSettings?.industry_template?.title || `Unlock your possibilities with ${whitelabelName}`}
        subtitle={merchantSettings?.industry_template?.body || "Don't let a lack of financing options hold you back!|We provide access to over 20 lenders to help with all of your financing needs."}
        hero={merchantSettings?.industry_template?.hero_image || "/assets/images/merchantLandingBanner.png"}
        titleBelowFold="See how much you qualify for in just a few minutes with no impact to your credit score."
        feat1="Terms up to 144 months"
        feat2="Rates starting at 5.99%"
        feat3="Loans up to $100,000"
        feat4="Access to 20+ lenders"
        stepsTitle="Apply for your loan in 4 easy steps!"
        step1="Fill out a simple, mobile friendly application"
        step2="View your available loan offers"
        step3="Choose the offer that best fits your budget"
        step4="Finalize your application with your chosen lender"
        type="MERCHANT"
        onCtaLinkClick={handleCtaLinkClick}
      ></Landing>}
    </>
  );
};

export default MerchantLanding;
